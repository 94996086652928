import AuthHeader  from '../helpers/auth-header';
const basicEndpointUrl = window.location.origin;

const UserService = {
    GetUsers,
    GetUser,
    CreateUser,
    UpdateUser,
    IsUserAdmin
}


async function GetUsers() {
    const requestOptions = { method: 'GET', headers: AuthHeader() };
    return fetch(`${basicEndpointUrl}/api/users/`, requestOptions);
}

async function GetUser(userId) {
    const requestOptions = { method: 'GET'};
    return fetch(`${basicEndpointUrl}/api/users/${userId}`, requestOptions);
}

function CreateUser(userModel){
    const requestOptions = { 
        method: 'POST',
        headers: AuthHeader(),
        body: JSON.stringify(userModel)
        };
    return fetch(`${basicEndpointUrl}/api/users/`,requestOptions)
}

function UpdateUser(userModel){
    const requestOptions = { 
        method: 'POST',
        headers: AuthHeader(),
        body: JSON.stringify(userModel)
        };
    return fetch(`${basicEndpointUrl}/api/users/${userModel.UserId}`,requestOptions)
}

function IsUserAdmin(vkUserId){
    return fetch(`${basicEndpointUrl}/api/users/${vkUserId}/adminallowed`)
}

export default UserService;



