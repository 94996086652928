const DateHelper = {
  GetDateByDayOfWeek,
  GetDayOfWeekShortName,
  GetDayOfWeekFullName,
  GetEventSchedulePublishTime,
  GetWeekFirstDate,
  GetWeekFirstDateByDate,
  GetWeekLastDate,
  GetWeekLastDateByDate,
  GetServerUTCTimeString,
  SetZeroToNumber,
  TimeToDate,
  TimeToDateAsTicks,
  IsSunday
}

Date.prototype.formatDDMMYYYY = function(){
  return this.getDate() + 
  "." +  (this.getMonth() + 1) +
  "." +  this.getFullYear();
}

Date.prototype.formatDDMM = function(){
  return this.getDate() + 
  "." +  (this.getMonth() + 1);
}

const timeToApply = 5; 

function SetZeroToNumber(i) { let s = i + ""; let len = i.length || 2; while (s.length < len) s = "0" + s; return s; }

function GetDateObjFromString(dateStr) {

}

function TimeToDateAsTicks(time){
  var today = new Date();
  var correctUTCTime = new Date(`${today.getUTCFullYear()}-${SetZeroToNumber(today.getUTCMonth() + 1)}-${SetZeroToNumber(today.getUTCDate())} ${time}Z`).getTime();
  return correctUTCTime;
}


function TimeToDate(time) {
  var today = new Date();
  var correctUTCTime = new Date(`${today.getUTCFullYear()}-${SetZeroToNumber(today.getUTCMonth() + 1)}-${SetZeroToNumber(today.getUTCDate())} ${time}Z`);
  return correctUTCTime;
  //other way, but it gives not in UTC but only changes time
  //var newTime = new Date('1970-01-01' + ' ' + time + 'Z').getTime();
  //var date = today.setHours(0, 0, 0, 0);
  //var DateTime = new Date(date + newTime);
  //return DateTime;
}

function GetDayOfWeekShortName(dayOfWeek) {
  switch (dayOfWeek) {
    case 1: {
      return `Пн`;
      break;
    }
    case 2: {
      return `Вт`;
      break;
    }
    case 3: {
      return `Ср`;
      break;
    }
    case 4: {
      return `Чт`;
      break;
    }
    case 5: {
      return `Пт`;
      break;
    }
    case 6: {
      return `Сб`;
      break;
    }
    case 7:
    default: {
      return `Вс`;
      break;
    }
  }
};

function GetDayOfWeekFullName(dayOfWeek) {

  switch (dayOfWeek) {
    case 1:
      return 'Понедельник';
      break;
    case 2:
      return 'Вторник';
      break;
    case 3:
      return 'Среда';
      break;
    case 4:
      return 'Четверг';
      break;
    case 5:
      return 'Пятница';
      break;
    case 6:
      return 'Суббота';
      break;
    case 7:
    default:
      return 'Воскресенье';
      break;
  }
};

function GetDateByDayOfWeek(dayOfWeek) {
  let curr = new Date();
  let resultDate = new Date();
  let currentDayOfWeek = curr.getDay();
  if (currentDayOfWeek == 0) {
    currentDayOfWeek = 7;
  }
  resultDate.setDate(curr.getDate() - currentDayOfWeek + 1 + dayOfWeek);
  return `(${SetZeroToNumber(resultDate.getDate())}.${SetZeroToNumber(resultDate.getMonth() + 1)})`
}

function GetServerUTCTimeString(includeSeconds) {
  var result = new Date();
  if (includeSeconds != undefined && includeSeconds) {
    return `${SetZeroToNumber(result.getUTCHours())}:${SetZeroToNumber(result.getUTCMinutes())}:${SetZeroToNumber(result.getSeconds())}`;
  }
  else {
    return `${SetZeroToNumber(result.getUTCHours())}:${SetZeroToNumber(result.getUTCMinutes())}`;
  }
}


function GetEventSchedulePublishTime() {
  var publishInterval = timeToApply;
  var currServerTime = new Date();

  currServerTime.setMinutes(currServerTime.getMinutes() + publishInterval); // timestamp
  currServerTime = new Date(currServerTime); // Date object

  var hours = SetZeroToNumber(currServerTime.getUTCHours());

  var minutes = SetZeroToNumber(currServerTime.getUTCMinutes());

  var result = `${hours}:${minutes}`;
  console.log(result);
  return result;
}

function GetWeekFirstDate() {
  let curr = new Date(); // get current date
  let firstday = new Date();
  let newDate = new Date();
  let dayOfWeek = curr.getDay();
  console.log("firstday");
  console.log(firstday);
  console.log(dayOfWeek);
  if (dayOfWeek == 0) {
    dayOfWeek = 7;
  }

  newDate.setDate(firstday.getDate() - dayOfWeek + 1);
  
  console.log(newDate);
  return newDate;
}

function GetWeekFirstDateByDate(date) {
  let dateToCheck = date; // get current date
  let firstday = date;
  let dayOfWeek = dateToCheck.getDay();
  if (dayOfWeek == 0) {
    dayOfWeek = 7;
  }

  firstday.setDate(dateToCheck.getDate() - dayOfWeek + 1);
  return firstday;
}

function IsSunday() {
  return new Date().getDay() == 0;
}

function GetWeekLastDate() {
  let curr = new Date(); // get current date
  let dayOfWeek = curr.getDay();
  if (dayOfWeek == 0) {
    dayOfWeek = 7;
  }

  let lastday = new Date();
  lastday.setDate(curr.getDate() + 7 - dayOfWeek);
  return lastday;
}

function GetWeekLastDateByDate(date) {
  console.log("date");
  console.log(date);
  let dateToCheck = new Date(date); // get current date
  console.log("dateToCheck");
  console.log(dateToCheck);
  let dayOfWeek = dateToCheck.getDay();
  if (dayOfWeek == 0) {
    dayOfWeek = 7;
  }
  let lastday = new Date();
  lastday.setDate(dateToCheck.getDate() + 7 - dayOfWeek);
  return lastday;
}


export default DateHelper;