import AuthHeader  from '../helpers/auth-header';

const basicEndpointUrl = window.location.origin;

const ImagesService = {
//    GetEventImages,
//    GetUserImages,
    AddEventImages,
    AddUserImages,
    DeleteEventImage,
    DeleteUserImage
}

const HttpContentTypes = {
    'json':'application/json',
    'dataForm':'application/x-www-form-urlencoded',
    'dataFormWithfiles':'multipart/form-data'
}


function GetEventImages(eventId) {
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/images`);
};

function GetUserImages(userId) {
    return fetch(`${basicEndpointUrl}/api/users/${userId}/images`);
};

function AddEventImages(eventId, image, userId) {
    const data = new FormData;
    data.append('image', image);
    data.append('userId',userId);
    const requestOptions = {
        method: 'POST',
        headers:AuthHeader(),
        body: data
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/image`, requestOptions);
};

function AddUserImages(userId, image) {
    const data = new FormData;
    data.append('image', image);
    data.append('userId', userId);
    const requestOptions = {
        method: 'POST',
        headers:AuthHeader(),
        body: data
    };
    return fetch(`${basicEndpointUrl}/api/users/${userId}/image`, requestOptions);
};

function DeleteEventImage(eventId, imageId){
    const requestOptions = {
        method: 'DELETE',
       headers:AuthHeader(),
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/image/${imageId}`, requestOptions);
}

function DeleteUserImage(userId, imageId){
    const requestOptions = {
        method: 'DELETE',
       headers:AuthHeader(),
    };
    return fetch(`${basicEndpointUrl}/api/users/${userId}/image/${imageId}`, requestOptions);
}

export default ImagesService
