import AuthHeader  from '../helpers/auth-header';

const basicEndpointUrl = window.location.origin;

const EventService = {
    GetEvents,
    GetEvent,
    GetEventByWeek,
    GetEventSchedule,
    GetUserEvents,
    CreateApplyRequest,
    CancelApplyRequest,
    CreateEvent,
    UpdateEvent,
    AddEventNotification,
    DeleteEventNotification
}

function AddEventNotification(eventId, notification){
    const requestOptions = {
        method: 'POST',
        headers: AuthHeader(),
        body: JSON.stringify(notification)
    };

   
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/notification`, requestOptions);
};

function CreateApplyRequest(eventId, applyRequestInfo){
    const requestOptions = {
        method: 'POST',
        headers: AuthHeader(),
        body: JSON.stringify(applyRequestInfo)
    };
   
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/createapplyrequest`, requestOptions)
}

function CancelApplyRequest(eventId, applyRequest){
    const requestOptions = {
        method: 'POST',
        headers: AuthHeader(),
        body: JSON.stringify(applyRequest)
    };
   
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/cancelapplyrequest`, requestOptions)
}


function DeleteEventNotification(eventId,notificationId){
    const requestOptions = {
        method: 'DELETE',
       headers:AuthHeader(),
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/notification/${notificationId}`, requestOptions);
};

function GetEvents() {
    return fetch(`${basicEndpointUrl}/api/events`);
};

function GetEvent(eventId) {
    return fetch(`${basicEndpointUrl}/api/events/${eventId}`);
};

function GetEventByWeek(eventId, weekFirstDate) {
    weekFirstDate.setHours(0);
    weekFirstDate.setMinutes(0)
    weekFirstDate.setSeconds(0);
    return fetch(`${basicEndpointUrl}/api/events/${eventId}?weekFirstDate=${weekFirstDate.toISOString()}`);
};

function GetEventSchedule(eventId) {
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/schedule`);
};
function GetUserEvents(userId) {
    const requestOptions = {
        headers: AuthHeader()
    };
    return fetch(`${basicEndpointUrl}/api/users/${userId}/events`, requestOptions);
};

function CreateEvent(eventObject) {
    const requestOptions = {
        method: 'PUT',
        headers: AuthHeader(),
        body: JSON.stringify(eventObject)
    };
    return fetch(`${basicEndpointUrl}/api/events`, requestOptions);
};

function UpdateEvent(eventObject) {
    const requestOptions = {
        method: 'POST',
        headers: AuthHeader(),
        body: JSON.stringify(eventObject)
    };

    let eventId = eventObject.id;
    return fetch(`${basicEndpointUrl}/api/events/${eventId}`, requestOptions);
};

export default EventService
