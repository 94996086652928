import AuthService from '../Services/AuthService';

function AuthHeader() {
    // return authorization header with jwt token
    const currentUser = AuthService.GetCurrentUserValue();
    if (currentUser && currentUser.token) {
        return {
            Authorization: `Bearer ${currentUser.token}`,
            //'Content-Type': 'application/json'
        };
    } else {
        return {};
    }
}
export default AuthHeader