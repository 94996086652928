import React, { Component } from 'react';
import bridge from '@vkontakte/vk-bridge';
//import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import '@vkontakte/vkui/dist/vkui.css';
import { Epic, Tabbar, TabbarItem} from '@vkontakte/vkui';


//import Home from './panels/Home';
import EventsView from './panels/EventsView';
import AboutUsView from './panels/AboutUs';
import AdminPanelView from './panels/AdminView';
import UserService from './Services/UserService';

import Icon28LocationOutline from '@vkontakte/icons/dist/28/location_outline';

import Icon28UserCircleOutline from '@vkontakte/icons/dist/28/user_circle_outline';
import Icon28ServicesOutline from '@vkontakte/icons/dist/28/services_outline';
// const PanelsDictionary = {
// 	Home:{PanelHeader:"Агуля", PanelTitle:"Главная"},
// 	Events:{PanelTitle:"Прогулки"},
// 	AboutUs:{PanelTitle:"О нас"}
// }

// class App extends React.Component {



// constructor(props)
// {
// 	super(props);
// 	this.state ={

// 	}
// }
// };

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeStory: 'eventsView',
			fetchedUser: null,
			isUserAdmin: false
		};
		this.onStoryChange = this.onStoryChange.bind(this);
	}

	componentDidMount() {
		//console.log("app component did mount ");
		bridge.subscribe(({ detail: { type, data } }) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});

		this.fetchData();
		//console.log("app component mounted ");
	}

	async fetchData() {
		this.setState({ isUserAdmin: true })
		const user = await bridge.send('VKWebAppGetUserInfo');
		this.setState({ fetchedUser: user });
		if (user != null) {
			//Check by VK Id that this user can be admin 
			// UserService.IsUserAdmin(user.id).then(res => res.text()).then((isAdmin) => {
			// 	if (isAdmin === "true") {
			// 		this.setState({ isUserAdmin: true })
			// 	}
			
			// });
		}

		let agulyaGroupId = 152403838;
		//let userid = 77076;
		//let groupAccessToken = "731a070a5365b4b69e69b58ed5f190972a5f758814817918bf015128424153e59fbe7202370f841bfd8b5";


		// await bridge.send("VKWebAppCallAPIMethod", {"method": "messages.isMessagesFromGroupAllowed", "request_id": "32test", "params": {"user_id": "77076","group_id":agulyaGroupId, "v":"5.122", "access_token":groupAccessToken}}).then(res =>  {

		// let isAllowed = res.response.is_allowed;
		// if (isAllowed == false){
		// 	bridge.send("VKWebAppAllowMessagesFromGroup", {"group_id": agulyaGroupId, "key": "whoisthepresident"});
		// }
		// }).catch( err => { console.log(err)});
	}

	onStoryChange(e) {
		this.setState({ activeStory: e.currentTarget.dataset.story })
	}

	ShowAdminSection() {
		if (window.location.href.indexOf("/admin/") != -1){
			//console.log(window.location.href.indexOf("/admin/"));
			return true;
		}
		return false;
	}

	render() {
		let showAdminSection = this.ShowAdminSection();
		let adminSection = null;
		if (this.state.isUserAdmin) {
			adminSection = <TabbarItem
				onClick={this.onStoryChange}
				selected={this.state.activeStory === 'adminView'}
				data-story="adminView"
				text="Панель ведущего"
			>
				<Icon28ServicesOutline/>
			</TabbarItem>;
		}

		return (
			<React.Fragment>
{showAdminSection != true && <EventsView id="eventsView" vkUser={this.state.fetchedUser} ></EventsView>}
{showAdminSection == true && <AdminPanelView id="adminView" vkUser={this.state.fetchedUser}></AdminPanelView>}
			</React.Fragment>
			//<EventsView id="eventsView" vkUser={this.state.fetchedUser} ></EventsView>
			// <Epic activeStory={this.state.activeStory} tabbar={
			// 	<Tabbar>
			// 		<TabbarItem
			// 			onClick={this.onStoryChange}
			// 			selected={this.state.activeStory === 'eventsView'}
			// 			data-story="eventsView"
			// 			text="Площадки"
			// 		><Icon28LocationOutline /></TabbarItem>
			// 		{/* <TabbarItem
			//   onClick={this.onStoryChange}
			//   selected={this.state.activeStory === 'services'}
			//   data-story="services"
			//   text="Сервисы"
			// ><Icon28ServicesOutline/></TabbarItem>
			// <TabbarItem
			//   onClick={this.onStoryChange}
			//   selected={this.state.activeStory === 'messages'}
			//   data-story="messages"
			//   label=""
			//   text="Сообщения"
			// ><Icon28MessageOutline /></TabbarItem>
			// <TabbarItem
			//   onClick={this.onStoryChange}
			//   selected={this.state.activeStory === 'clips'}
			//   data-story="clips"
			//   text="Клипы"
			// ><Icon28ClipOutline /></TabbarItem> */}

			// 		{/* {adminSection} */}


			// 		<TabbarItem
			// 	onClick={this.onStoryChange}
			// 	selected={this.state.activeStory === 'adminView'}
			// 	data-story="adminView"
			// 	text="Панель ведущего"
			// >
			// 	<Icon28ServicesOutline/>
			// </TabbarItem>
			
			// 		<TabbarItem
			// 			onClick={this.onStoryChange}
			// 			selected={this.state.activeStory === 'aboutUs'}
			// 			data-story="aboutUs"
			// 			text="О нас"
			// 		><Icon28UserCircleOutline /></TabbarItem>
			// 	</Tabbar>
			// }>
			// 	<EventsView id="eventsView" vkUser={this.state.fetchedUser} ></EventsView>

			// 	{/* <View id="services" activePanel="services">
			// 		<Panel id="services">
			// 			<PanelHeader>Сервисы</PanelHeader>
			// 		</Panel>
			// 	</View> */}
			// 	{/* <View id="messages" activePanel="messages">
			// 		<Panel id="messages">
			// 			<PanelHeader>Сообщения</PanelHeader>
			// 		</Panel>
			// 	</View> */}
			// 	{/* <View id="clips" activePanel="clips">
			// 		<Panel id="clips">
			// 			<PanelHeader>Клипы</PanelHeader>
			// 			{this.state.fetchedUser &&
			// 				<Group title="User Data Fetched with VK Bridge">
			// 					<Cell
			// 						before={this.state.fetchedUser.photo_200 ? <Avatar src={this.state.fetchedUser.photo_200} /> : null}
			// 						description={this.state.fetchedUser.city && this.state.fetchedUser.city.title ? this.state.fetchedUser.city.title : ''}
			// 					>
			// 						{`${this.state.fetchedUser.first_name} ${this.state.fetchedUser.last_name}`}
			// 					</Cell>
			// 				</Group>}
			// 		</Panel>
			// 	</View> */}

			// 	<AdminPanelView id="adminView" vkUser={this.state.fetchedUser}></AdminPanelView>
			// 	<AboutUsView id="aboutUs"></AboutUsView>
			// 	{this.state.infoMessage}
			// </Epic>
		)
	}
}




// const Appp = () => {
// 	const [activePanel, setActivePanel] = useState('home');
// 	const [fetchedUser, setUser] = useState(null);
// 	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);


// 	useEffect(() => {
// 		bridge.subscribe(({ detail: { type, data }}) => {
// 			if (type === 'VKWebAppUpdateConfig') {
// 				const schemeAttribute = document.createAttribute('scheme');
// 				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
// 				document.body.attributes.setNamedItem(schemeAttribute);
// 			}
// 		});
// 		async function fetchData() {
// 			const user = await bridge.send('VKWebAppGetUserInfo');

// 			setUser(user);
// 			setPopout(null);
// 		}
// 		fetchData();
// 	}, []);

// 	const go = e => {
// 		setActivePanel(e.currentTarget.dataset.to);
// 	};



// 	return (
// 		<View activePanel={activePanel} popout={popout}>
// 			<Home id='home'  fetchedUser={fetchedUser} go={go} />

// 			<Events id='events'  go={go} />
// 		</View>
// 	);
// }

export default App;

