import bridge from '@vkontakte/vk-bridge';

const VKservice = {
    GetUserInfo    
}

//const user = await bridge.send('VKWebAppGetUserInfo');

async function GetUserInfo(){
    return await bridge.send('VKWebAppGetUserInfo');
}

export default VKservice;