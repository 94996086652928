import React from 'react';

function setZeroToNumber(i) { let s = i + ""; let len = i.length || 2; while (s.length < len) s = "0" + s; return s; }

function GetDateByDayOfWeek(dayOfWeek){
   let curr = new Date();
 let resultDate = new Date();
    let currentDayOfWeek = curr.getDay();
    if (currentDayOfWeek == 0) {
      currentDayOfWeek = 7;
    }
    resultDate.setDate(curr.getDate() - currentDayOfWeek + 1 + dayOfWeek);
   return `(${setZeroToNumber(resultDate.getDate())}.${setZeroToNumber(resultDate.getMonth() + 1)})`
}


const EventScheduleTemplate = (props) => (

   <table className="scheduleContentTable">
        {GetTableHeader(props.useFullVersion)}
        {GetTableBody(props.eventSchedules, props.OpenResponsibleProfile, props.OpenSchedulePanel, props.useFullVersion)}
   </table>   
);


function GetTableHeader(useFullVersion){
   if (useFullVersion){
      return  <thead><tr><td>День недели</td><td>Площадка</td><td>Время</td><td>Ведущий</td><td>Цена</td></tr></thead>
   }

return  <thead><tr><td>День недели</td> <td>Время</td><td>Ведущий</td></tr></thead>
}

function GetTableBody(schedules, OpenResponsibleProfileFunction, OpenSchedulePanelFunction, useFullVersion) {
   if (!schedules) {
      return <tbody>
         <tr><td>Пн {GetDateByDayOfWeek(0)}</td><td></td><td> </td></tr>
         <tr><td>Вт {GetDateByDayOfWeek(1)}</td><td></td><td> </td></tr>
         <tr><td>Ср {GetDateByDayOfWeek(2)}</td><td></td><td> </td></tr>
         <tr><td>Чт {GetDateByDayOfWeek(3)}</td><td></td><td> </td></tr>
         <tr><td>Пт {GetDateByDayOfWeek(4)}</td><td></td><td> </td></tr>
         <tr><td>Сб {GetDateByDayOfWeek(5)}</td><td></td><td> </td></tr>
         <tr><td>Вс {GetDateByDayOfWeek(6)}</td><td></td><td> </td></tr>
      </tbody>
   }
   else {
      //not empty
      if (useFullVersion) {
         return <tbody>
            {schedules.map(schedule =>
               <tr>
                  <td>{schedule.dayTitle}</td>
                  {RenderEventSchedule(schedule.eventSchedules, OpenResponsibleProfileFunction, OpenSchedulePanelFunction)}
               </tr>)
            }
         </tbody>
      }
      else {
         return <tbody>
            {schedules.map(eventSchedule =>
               <tr>
                  <td>{eventSchedule.dayTitle}</td>
                  <td>{eventSchedule.times.map(x => <div> {x.time}</div>)}</td>
                  <td>{eventSchedule.times.map(x => <a className='pointer link' onClick={() => OpenResponsibleProfileFunction(x.responsible)}>{x.responsible.name} {x.responsible.surname}</a>)} </td>
               </tr>)
            }
         </tbody>
      }
   }
}
       
function RenderEventSchedule(eventSchedules, OpenResponsibleProfileFunction, OpenSchedulePanelFunction){

   if (eventSchedules != undefined && eventSchedules.length > 0 )
   {
      return <React.Fragment>
      {eventSchedules.map(eventSchedule => 
      <React.Fragment>
      <td><a 
      className="pointer link" onClick={() => OpenSchedulePanelFunction(eventSchedule.eventId)}
      >{eventSchedule.eventAddress}</a></td>
      <td>{eventSchedule.times.map(x => <div> {x.time}</div>)}</td>
      <td>{eventSchedule.times.map(x => <a className='pointer link' onClick={() => OpenResponsibleProfileFunction(x.responsible)}>{x.responsible.name} {x.responsible.surname}</a>)} </td>
      <td>{eventSchedule.eventPrice}</td>
      </React.Fragment>)}
   </React.Fragment>  
   }
return <React.Fragment>
<td></td>
<td></td>
<td></td>
<td></td>
</React.Fragment>
};      
// const EventScheduleTemplate = (props) => (

// <table className="scheduleContentTable">
//      <thead><tr><td>День недели</td> <td>Время</td><td>Ведущий</td></tr></thead>
     
//     {!props.eventSchedules && 
//     <tbody>
//           <tr><td>Пн {GetDateByDayOfWeek(0)}</td><td></td><td> </td></tr>
//           <tr><td>Вт {GetDateByDayOfWeek(1)}</td><td></td><td> </td></tr>
//           <tr><td>Ср {GetDateByDayOfWeek(2)}</td><td></td><td> </td></tr>
//           <tr><td>Чт {GetDateByDayOfWeek(3)}</td><td></td><td> </td></tr>
//           <tr><td>Пт {GetDateByDayOfWeek(4)}</td><td></td><td> </td></tr>
//           <tr><td>Сб {GetDateByDayOfWeek(5)}</td><td></td><td> </td></tr>
//           <tr><td>Вс {GetDateByDayOfWeek(6)}</td><td></td><td> </td></tr>
//     </tbody>  
//     }
//     {props.eventSchedules && 
//      <tbody>
// {props.eventSchedules.map(eventSchedule=> 
// <tr>   
// <td>{eventSchedule.dayTitle}</td>
// <td>{eventSchedule.times.map(x =><div> {x.time}</div> )}</td>
// <td>{eventSchedule.times.map(x => <a className='pointer link' onClick={() => props.OpenResponsibleProfile(x.responsible)}>{x.responsible.name} {x.responsible.surname }</a>)} </td>
// </tr>)
// }
//     </tbody> 
//     } 
//    </table>

// );

export default EventScheduleTemplate;