const basicEndpointUrl = window.location.origin;

const UserFavoritesService = {
    AddFavoriteEvent,
    DeleteFavoriteEvent,
    GetUserFavoriteEvents
    }

async function AddFavoriteEvent(user, eventId){
    let userFavoriteBody = {
        "user":user,
        "eventId": eventId
      };

     return fetch(`${basicEndpointUrl}/api/favorites/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(userFavoriteBody)
      });
}


async function DeleteFavoriteEvent(user, eventId){
    let userFavoriteBody = {
        "user":user,
        "eventId": eventId
      };

     return fetch(`${basicEndpointUrl}/api/favorites/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(userFavoriteBody)
      });
}


async function GetUserFavoriteEvents(user){
    let userFavoriteBody = {
        "user": user
      };

     return fetch(`${basicEndpointUrl}/api/favorites`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(userFavoriteBody)
      });
}

export default UserFavoritesService;