import React , { Component } from 'react';
//import PropTypes from 'prop-types';
//import { platform, IOS, List } from '@vkontakte/vkui';
import View from '@vkontakte/vkui/dist/components/View/View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';

import './common.css';

class AboutUsView extends Component{

    constructor (props) {
        super(props);
    
        this.state = {
          
        };
        
      }
    
      render(){
          return ( 
<View id={this.props.id} activePanel={this.props.id}>
    <Panel id={this.props.id}>
                <PanelHeader>О Нас</PanelHeader>
    <div className="info-section">
    <iframe title="agulya Video" className="centered iframe-video" src="https://vk.com/video_ext.php?oid=-152403838&id=456239017&hash=5fc8bda511494c30&hd=2" frameborder="0" allowfullscreen></iframe>
    <div>         <p>Приходите на увлекательные прогулки для детей от 5 лет с аниматором на свежем воздухе.</p>
    <p>Наши программы разработаны специалистами по педагогике и спорту, включают оздоровительные упражнения и подвижные игры.</p>
    <p>Прогулка длится 1 ч 15 мин в группе до 10 человек на игровой площадке рядом с Вашим домом.</p></div>     

    <div><h3>Детям такая прогулка даст:</h3>
    <ul><li> радостные эмоции</li>
    <li> укрепление здоровья </li>
    <li> общение со сверстниками </li>
    <li> желание чаще находится на свежем воздухе</li>
    </ul>
    </div>
    <h3>А родителям поможет:</h3>
    <ul>
    <li> в закалке и оздоровлении детей </li>
    <li> их социальном и эмоциональном развитии </li>
    <li> снижении возможной гаджет-зависимости </li>
    <li> безопасном нахождение ребенка на улице </li>
    </ul>
    <p><b>Стоимость прогулки за ребенка составляет до 150 р. на буднях и до 200 р. по выходным.</b></p>

    <div><a href="https://vk.com/agulya_club">Группа Вконтакте </a> позволит Вам узнать больше о нас, задать интересующие вопросы, записаться на прогулку в любой из наших локаций.</div>

    <h3>Приходите, будет весело! =)</h3>

    <h2>Наши контакты:</h2>
    <p><b>тел.: <a href="tel:+79219043964">+7 (921) 904 39 64</a></b></p>
    <p><b>почта: <a href="mailto:agulya_club@mail.ru?subject=Вопросы по Агуле">agulya_club@mail.ru</a></b></p>
    </div>
    </Panel>
</View>

          )
      }
}


export default AboutUsView;