var AdminPanelsEnum = {
    AdminLoginPanel : "adminLoginPanel",
    AdminAccountConfig:"adminAccountConfig",
    AddEventPanel:"addEventPanel",
    AdminEventsConfig:"adminEventsConfig",
    AdminMainPanel:"adminMainPanel",
    AdminUsersConfig:"adminUsersConfig",
    AdminEditEventMain:"adminEditEventMain",
    AdminEditEventImages:"adminEditEventImages",
    AdminEditEventSchedule:"adminEditEventSchedule",
    AdminEditEventMessages:"adminEditEventMessages",
    AdminEditEvent:"adminEditEvent",
    AdminAddUser:"adminAddUser",
    UserCreated:"userCreated"
}

export default AdminPanelsEnum;