import React from 'react';

function setZeroToNumber(i) { let s = i + ""; let len = i.length || 2; while (s.length < len) s = "0" + s; return s; }

function GetDateByDayOfWeek(dayOfWeek){
   let curr = new Date();
 let resultDate = new Date();
    let currentDayOfWeek = curr.getDay();
    if (currentDayOfWeek == 0) {
      currentDayOfWeek = 7;
    }
    resultDate.setDate(curr.getDate() - currentDayOfWeek + 1 + dayOfWeek);
   return `(${setZeroToNumber(resultDate.getDate())}.${setZeroToNumber(resultDate.getMonth() + 1)})`
}

const EventScheduleTemplateLight = (props) => (

   props.eventSchedules.map(eventSchedule =>
      <React.Fragment>
         {eventSchedule.times.length == 0 && <div className={'schedule-panel-row'}>
         <div className="schedule-panel-left padding-left-2p">{eventSchedule.dayTitle}</div>
         <div className="schedule-panel-div padding-left-3p"> - </div>
         <div className="schedule-panel-right"></div>
      </div>}
         {eventSchedule.times.length != 0 && <div className={'schedule-panel-row pointer'} onClick={() => props.OpenScheduleDayInfo(eventSchedule)}>
         <div className="schedule-panel-left padding-left-2p">{eventSchedule.dayTitle}</div>
         <div className="schedule-panel-div padding-left-3p">{eventSchedule.times.map(x => <div> {x.time}</div>)}</div>
         <div className="schedule-panel-right"></div>
      </div>}
      </React.Fragment>

      
   )

   // <table className="scheduleContentTable">
   //      {GetTableHeader()}
   //      {GetTableBody(props.eventSchedules, props.OpenResponsibleProfile)}
   // </table>   
);



function GetTableHeader(){
   return  <thead><tr><td>День недели</td> <td>Время</td><td>Ведущий</td></tr></thead>
}

function GetTableBody(schedules, OpenResponsibleProfileFunction) {
   if (!schedules) {
      return <tbody>
         <tr><td>Пн {GetDateByDayOfWeek(0)}</td><td></td><td> </td></tr>
         <tr><td>Вт {GetDateByDayOfWeek(1)}</td><td></td><td> </td></tr>
         <tr><td>Ср {GetDateByDayOfWeek(2)}</td><td></td><td> </td></tr>
         <tr><td>Чт {GetDateByDayOfWeek(3)}</td><td></td><td> </td></tr>
         <tr><td>Пт {GetDateByDayOfWeek(4)}</td><td></td><td> </td></tr>
         <tr><td>Сб {GetDateByDayOfWeek(5)}</td><td></td><td> </td></tr>
         <tr><td>Вс {GetDateByDayOfWeek(6)}</td><td></td><td> </td></tr>
      </tbody>
   }
   else {
      //not empty
      
         return <tbody>
            {schedules.map(eventSchedule =>
               <tr>
                  <td>{eventSchedule.dayTitle}</td>
                  <td>{eventSchedule.times.map(x => <div> {x.time}</div>)}</td>
                  <td>{eventSchedule.times.map(x => <a className='pointer link' onClick={() => OpenResponsibleProfileFunction(x.responsible)}>{x.responsible.name} {x.responsible.surname}</a>)} </td>
               </tr>)
            }
         </tbody>
      }
}
       

export default EventScheduleTemplateLight;