import AuthHeader  from '../helpers/auth-header';

const basicEndpointUrl = window.location.origin;

const SchedulesService = {
    CreateEventSchedule,
    CreateEventScheduleTemplate,
    UpdateEventSchedule,
    UpdateEventScheduleTemplate,
    SaveEventScheduleTemplates,
    ApplySchedule,
    DeleteEventSchedule,
    DeleteEventScheduleTemplate,
    CancelChangeRequst
    }

async function CancelChangeRequst(eventId, requestId){
    const requestOptions = {
        method: 'DELETE',
       headers:AuthHeader(),
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/requests/${requestId}`, requestOptions);
}


async function CreateEventScheduleTemplate(eventScheduleTemplate){
    let eventId = eventScheduleTemplate.eventId;
    const requestOptions = {
        method: 'PUT',
       headers:AuthHeader(),
       body: JSON.stringify(eventScheduleTemplate)
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/scheduleTemplate`, requestOptions);
};

async function CreateEventSchedule(eventSchedule){
    let eventId = eventSchedule.eventId;
    const requestOptions = {
        method: 'PUT',
       headers:AuthHeader(),
       body: JSON.stringify(eventSchedule)
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/schedule`, requestOptions);
};

async function UpdateEventSchedule(eventId, scheduleId, eventSchedule){
    const requestOptions = {
        method: 'POST',
       headers:AuthHeader(),
       body: JSON.stringify(eventSchedule)
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/schedule/${scheduleId}`, requestOptions);
};

async function UpdateEventScheduleTemplate(eventId, scheduleTemplateId, eventScheduleTemplate){
    const requestOptions = {
        method: 'POST',
       headers:AuthHeader(),
       body: JSON.stringify(eventScheduleTemplate)
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/scheduletemplate/${scheduleTemplateId}`, requestOptions);
};

async function SaveEventScheduleTemplates(eventId, eventScheduleTemplates){
    //console.log(eventScheduleTemplates);
    const requestOptions = {
        method: 'POST',
       headers:AuthHeader(),
       body: JSON.stringify(eventScheduleTemplates)
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/scheduletemplates`, requestOptions);
};

async function ApplySchedule(eventId, applyInfo){

    const requestOptions = {
        method: 'POST',
       headers:AuthHeader(),
       body: JSON.stringify(applyInfo)
    };
    return fetch(`${basicEndpointUrl}/api/events/${eventId}/applyschedules`, requestOptions);
};


    async function DeleteEventSchedule(eventId, scheduleId){
        const requestOptions = {
            method: 'DELETE',
           headers:AuthHeader(),
        };
        return fetch(`${basicEndpointUrl}/api/events/${eventId}/schedule/${scheduleId}`, requestOptions);
    }

    async function DeleteEventScheduleTemplate(eventId, scheduleTemplateId){
        const requestOptions = {
            method: 'DELETE',
           headers:AuthHeader(),
        };
        return fetch(`${basicEndpointUrl}/api/events/${eventId}/scheduleTemplate/${scheduleTemplateId}`, requestOptions);
    }

export default SchedulesService