import AuthService from '../Services/AuthService';

export function handleResponse(response) {
    return response.text().then(text => {
        let data = text;
        //console.log("response.ok");
        //console.log(response.ok);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                AuthService.Logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        try{
            data = JSON.parse(text);
            //console.log("data = ");
            //console.log(data);
        }
        catch(error){
            return Promise.reject(error);
        }

        return data;
    });
}