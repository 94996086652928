import React, { Component } from 'react';
import { Div } from '@vkontakte/vkui';
import { Icon28DoorArrowRightOutline } from '@vkontakte/icons';
import View from '@vkontakte/vkui/dist/components/View/View';
import Alert from '@vkontakte/vkui/dist/components/Alert/Alert';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderBack from '@vkontakte/vkui/dist/components/PanelHeaderBack/PanelHeaderBack';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Header from '@vkontakte/vkui/dist/components/Header/Header';
import SimpleCell from '@vkontakte/vkui/dist/components/Cell/Cell';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Input from '@vkontakte/vkui/dist/components/Input/Input';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';
import Select from '@vkontakte/vkui/dist/components/Select/Select';
import Textarea from '@vkontakte/vkui/dist/components/Textarea/Textarea';
import Card from '@vkontakte/vkui/dist/components/Card/Card';
import Radio from '@vkontakte/vkui/dist/components/Radio/Radio';
import CardScroll from '@vkontakte/vkui/dist/components/CardScroll/CardScroll';
import Snackbar from '@vkontakte/vkui/dist/components/Snackbar/Snackbar';

import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import Icon20Add from '@vkontakte/icons/dist/20/add';
import Icon20Cancel from '@vkontakte/icons/dist/20/cancel';
import Icon28UserOutline from '@vkontakte/icons/dist/28/user_outline';
import Icon28UsersOutline from '@vkontakte/icons/dist/28/users_outline';
import Icon28ListOutline from '@vkontakte/icons/dist/28/list_outline';
import Icon28DeleteOutline from '@vkontakte/icons/dist/28/delete_outline';
import Icon56InfoOutline from '@vkontakte/icons/dist/56/info_outline';
import Icon56DeleteOutlineIos from '@vkontakte/icons/dist/56/delete_outline_ios';
import Icon28AddCircleOutline from '@vkontakte/icons/dist/28/add_circle_outline';
import Icon56AddCircleOutline from '@vkontakte/icons/dist/56/add_circle_outline';
import Icon28EditOutline from '@vkontakte/icons/dist/28/edit_outline';
import Icon16ErrorCircleFill from '@vkontakte/icons/dist/16/error_circle_fill';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import { YMaps, Map, Placemark } from "react-yandex-maps";

//Services
import EventService from "../Services/EventService";
import AuthService from "../Services/AuthService";
import ImagesService from "../Services/ImagesService";
import UserService from '../Services/UserService';
import SchedulesService from '../Services/SchedulesService';
import DateHelper from '../helpers/dates-helper';
import AdminUsersConfigPanel from './AdminUsersConfigPanel';
import './common.css';


//enums
import AdminPanelsEnum from '../enums/panelEnums';

const emptySchedule = { time: "", dayOfWeek: 0, responsible: {} }
//const emptyEventModel = { id: 0, eventTypeId: 1, address: "", title: "", description: "", coordinateX: "", coordinateY: "", responsibleUser: { userId: 0 }, price: "" }
const emptyEventModel = { id: 0, eventTypeId: 1, address: "", title: "", description: "", coordinateX: "59.9661", coordinateY: "30.3113", responsibleUser: { userId: 0 }, price: "" }

const emptyScheduleTimeModel = { scheduleId: 0, eventId: 0, time: "15:00", responsibleUserId: 0 }

const emptyNewUser = { username: "test13", name: "test", surname: "test", email: "ky-kisky@yandex.ru" }

// const emptyScheduleModel = [
// 	{ dayOfWeek: 1, isActive: true, timeValues: [{ time: "15:00" }] },
// 	{ dayOfWeek: 2, isActive: false, timeValues: [] },
// 	{ dayOfWeek: 3, isActive: false, timeValues: [] },
// 	{ dayOfWeek: 4, isActive: false, timeValues: [] },
// 	{ dayOfWeek: 5, isActive: false, timeValues: [] },
// 	{ dayOfWeek: 6, isActive: false, timeValues: [] },
// 	{ dayOfWeek: 7, isActive: false, timeValues: [] }
// ]

class AdminPanelView extends Component {

	constructor(props) {
		super(props);

		var currentDate = DateHelper.GetWeekFirstDate();

		this.state = {
			activePanel: AdminPanelsEnum.AdminLoginPanel,
			firstPageMarkup: "",
			isUserAdmin: false,
			editUser: null,
			newUser: emptyNewUser,
			editUserImages: [],
			usersList: [],
			usernameValue: "",
			passwordValue: "",
			popout: null,
			snackbar: null,
			userEvents: [],
			isEditMode: false,
			editEvent: null,
			editEventSchedules: [],
			editEventHasSchedules: false,
			editEventWeekFirstDate: currentDate,
			editEventWeekLastDate: DateHelper.GetWeekLastDateByDate(currentDate),
			editEventApplyRequest: null,
			editSchedulesChangeRequests: [],
			editEventScheduleTemplates: [],
			editEventImages: [],
			editEventNotifications: [],
			editEventNewNotificationText: "",
			editEventTimeToPublish:DateHelper.GetEventSchedulePublishTime(false),
			newSchedule: emptySchedule,
			serverTime:DateHelper.GetServerUTCTimeString()
		}

		this.OnChange = this.OnChange.bind(this);
		this.HandleScheduleCheck = this.HandleScheduleCheck.bind(this);
		this.HandleSchedulePrice = this.HandleSchedulePrice.bind(this);
		this.HidePopout = this.HidePopout.bind(this);
		this.ShowSpinner = this.ShowSpinner.bind(this);
		this.ToggleVisible = this.ToggleVisible.bind(this);
	}

	componentDidMount() {
		if (AuthService.GetCurrentUserValue() != null) {
			this.LoadAdminData();
		}
		setInterval(() => {
			//serverTime = DateHelper.GetServerUTCTimeString();
			this.setState({serverTime : DateHelper.GetServerUTCTimeString(true)});
		}, 1000);
	}

	//Handlers

	ToggleVisible(event) {
		event.currentTarget.classList.toggle('hidden');
	}

	// handle click on the schedule checkbox
	HandleScheduleCheck(scheduleIndex) {
		let newSchedulesTemplates = this.state.editEventScheduleTemplates;
		let currentValue = newSchedulesTemplates[scheduleIndex].isActive
		newSchedulesTemplates[scheduleIndex].isActive = !currentValue;
		this.setState({ editEventScheduleTemplates: newSchedulesTemplates });
	}

	HandleSchedulePrice(scheduleIndex, priceInput) {
		let newSchedulesTemplates = this.state.editEventScheduleTemplates;

		let value = priceInput.target.value;
		newSchedulesTemplates[scheduleIndex].price = value;
		this.setState({ editEventScheduleTemplates: newSchedulesTemplates });
	}

	ToggleEditEventMode = () => {
		let currentValue = this.state.isEditMode;
		this.setState({ isEditMode: !currentValue });
	}

	OnChange(e) {
		const { name, value } = e.currentTarget;
		this.setState({ [name]: value });
	}

	HeaderBackLink = (panelName) => {
		return <PanelHeaderBack onClick={() => this.setState({ activePanel: panelName })} />
	}

	editUserChangeHandler = event => {
		event.persist();
		let value = event.target.value;
		this.setState(prevState => ({
			editUser: { ...prevState.editUser, [event.target.name]: value }
		}))
	};

	newUserChangeHandler = event => {
		event.persist();
		let value = event.target.value;
		this.setState(prevState => ({
			newUser: { ...prevState.newUser, [event.target.name]: value }
		}))
	}

	editEventChangeHandler = event => {
		event.persist();
		let value = event.target.value;
		this.setState(prevState => ({
			editEvent: { ...prevState.editEvent, [event.target.name]: value }
		}))
	};

	EditElementChangeHandler = (stateNameToSave, elementToChange) => {
		elementToChange.persist();

		let value = elementToChange.target.value;

		this.setState(prevState => ({
			stateNameToSave: { ...prevState.editEvent, [elementToChange.target.name]: value }
		}))
	};
	// EditNewScheduleChangeHandler = event => {
	// 	event.persist();
	// 	let value = event.target.value;
	// 	this.setState(prevState => ({
	// 		newSchedule: { ...prevState.newSchedule, [event.target.name]: value }
	// 	}))
	// }

	EditScheduleTemplateTimeChangeHandler = (event, scheduleId, timeIndex) => {
		event.persist();
		let newValue = event.target.value;
		let templates = this.state.editEventScheduleTemplates;
		templates[scheduleId].times[timeIndex].time = newValue
		this.setState({
			editEventScheduleTemplates: templates
		})
	}

	EditSchedulePublishTimeChangeHandler = (event) => {
		event.persist();
		this.setState({editEventTimeToPublish:event.target.value});
	}

	AddUserImageHandler = async (e) => {
		let user = AuthService.GetCurrentUserValue();
		this.ShowSpinner();
		await ImagesService.AddUserImages(user.UserId, e.target.files[0]).then(res => res.json()).then(results => {

			let images = this.state.editUserImages;
			for (let i = 0; i <= results.length - 1; i++) {
				images.push(results[i]);
			}
			this.setState({ editUserImages: images });
		}).catch(err => console.log(err)).finally(() => this.HidePopout());
	}

	AddEventImageHandler = async (e) => {
		let user = AuthService.GetCurrentUserValue();
		this.ShowSpinner();
		await ImagesService.AddEventImages(this.state.editEvent.id, e.target.files[0], user.UserId).then(res => res.json()).then(results => {

			let images = this.state.editEventImages;
			for (let i = 0; i <= results.length - 1; i++) {
				images.push(results[i]);
			}
			this.setState({ editEventImages: images });
		}).catch(err => console.log(err)).finally(() => this.HidePopout());
	}

	OpenNotificationAlert(messageMarkup) {
		this.setState({
			popout:
				<Alert
					actionsLayout="vertical"
					actions={[
						{
							title: 'Хорошо',
							autoclose: true,
							mode: 'cancel',

						}
					]}
					onClose={this.HidePopout}
				>
					{messageMarkup}
				</Alert>
		});
	}

	//Popouts
	OpenVKAlert() {

		this.setState({
			popout:
				<Alert
					actionsLayout="vertical"
					actions={[
						{
							title: 'Продолжить без перехода',
							autoclose: true,
							mode: 'destructive',

						},
						{
							title: 'Зайти через Вконтакте',
							autoclose: true,
							mode: 'cancel',
							action: () => window.open("https://vk.com/app7491526", "_self")
						}]}
					onClose={this.HidePopout}
				>
					<h2>Данная страница доступна только для ведущих и только через сайт Вконтакте!</h2>
					<p>Для просмотра всех возможностей ведущего, необходимо зайти в приложение через Vkontakte.</p>
				</Alert>
		});
	}

	ShowSpinner = () => {
		this.setState({ popout: <ScreenSpinner /> });
	}

	HidePopout = () => {
		this.setState({ popout: null });
	}

	ShowSnackBar = (message, asError = false) => {
		let icon = <Icon16Done fill="#fff" width={14} height={14} />
		if (asError) {
			icon = <Icon16ErrorCircleFill fill="#fff" width={14} height={14} />
		}

		this.setState({
			snackbar:
				<Snackbar
					onClose={() => this.setState({ snackbar: null })}
					//action="Поделиться"
					//onActionClick={() => this.setState({ text: "Добавляем метку." })}
					before={
						<Avatar size={24} style={{ background: "var(--accent)" }}>
							{icon}
						</Avatar>
					}
				>
					{message}
				</Snackbar>
		})
	}


	OpenImageDeletionPopout(imageIndex, funcCallback) {

		this.setState({
			popout:
				<Alert
					actionsLayout="vertical"
					actions={[
						{
							title: 'Удалить картинку',
							autoclose: true,
							mode: 'destructive',
							action: () => { funcCallback(imageIndex) },
						},
						{
							title: 'Отмена',
							autoclose: true,
							mode: 'cancel',

						}]}
					onClose={this.HidePopout}
				>
					<h2>Вы собираетесь удалить картинку!</h2>
					<p>Вы уверены в этом?</p>
				</Alert>
		});
	};

	//Functions 
	VerifyPassword = async () => {
		this.ShowSpinner();
		let userName = this.state.usernameValue;

		//TODO: Check the password on the site.. if password valid get the events  
		AuthService.Login(userName, this.state.passwordValue).then(userInfo => {


			//TODO: Get the data for the userEvents 
			//let userEventsUrl = "/api/users/"+ this.props.vkUser.id+ "/events"
			//EventService.GetUserEvents(this.props.vkUser.id)

			this.LoadAdminData();

		}).catch(error => {

			//console.log("ошибка авторизации");
			// TODO: show the error message on UI  
			this.setState({
				popout: <Alert
					actionsLayout="vertical"
					actions={[

						{
							title: 'закрыть',
							autoclose: true,
							mode: 'cancel'
						}]}
					onClose={this.HidePopout}
				>
					<h2>Ошибка авторизации!</h2>
					<p>Вы ввели не правильный пароль или у вас нет прав, для входа в панель администратора</p>
				</Alert>
			});
			console.log(error);
		}
		)//.finally(() => this.HidePopout());

	}

	Logout = () => {
		AuthService.Logout();
		this.setState({ activePanel: AdminPanelsEnum.AdminLoginPanel })
	}

	LoadAdminData() {

		let user = AuthService.GetCurrentUserValue();
		let isUserAdmin = AuthService.IsCurrentUserAdmin();

		//AuthService.CheckAuth();
		EventService.GetUserEvents(user.UserId).then(res => res.json()).then(events => {


			this.setState(
				{
					userEvents: events,
					activePanel: AdminPanelsEnum.AdminMainPanel,
					isUserAdmin: isUserAdmin
				}
			);

		})
			.catch(() => { console.log("AdminPage the error during response to enpoint api/events") })
			.finally(() => {
				this.HidePopout();
			})

	}

	DecreaseWeekToCheck = () => {
	   var previousWeek = new Date(this.state.editEventWeekFirstDate);
	   previousWeek.setDate(previousWeek.getDate() - 7);
	   var currentPreviousWeek = DateHelper.GetWeekFirstDate();
	   if (previousWeek.getDate() < currentPreviousWeek.getDate() || previousWeek.getMonth() < currentPreviousWeek.getMonth() || previousWeek.getFullYear() < currentPreviousWeek.getFullYear()){
this.OpenNotificationAlert("Дата не может быть в прошедшем времени.");
	   }
	   else {
		console.log("week first date is ");
		console.log(previousWeek);
		
		this.LoadEditEventDataByWeek(previousWeek);
		this.setState({editEventWeekFirstDate:previousWeek, editEventWeekLastDate:DateHelper.GetWeekLastDateByDate(previousWeek)});
	   }
	   
	}

	IncreaseWeekToCheck= () => {
		var nextWeekDate = new Date(this.state.editEventWeekFirstDate);
		
		nextWeekDate.setDate(nextWeekDate.getDate() + 7);
		
		
		this.LoadEditEventDataByWeek(nextWeekDate)
		this.setState({editEventWeekFirstDate:nextWeekDate, editEventWeekLastDate:DateHelper.GetWeekLastDateByDate(nextWeekDate)});
	 }

	LoadEditEventDataByWeek = (weekFirstDate)=>{
		this.ShowSpinner();
		//let weekFirstDate = this.state.editEventWeekFirstDate;
		var event = this.state.editEvent;
		EventService.GetEventByWeek(event.id, weekFirstDate).then(res => res.json()).then(eventData =>{
			console.log("loading eventData");
			console.log(eventData.schedules);
			this.setState({
				editEvent: eventData,
				//activePanel: "adminEditEvent",
				editEventSchedules: eventData.schedules,
				editEventHasSchedules: eventData.schedules.length != 0,
				editEventScheduleTemplates: eventData.scheduleTemplates,
				//editEventImages: eventData.images,
				//editEventNotifications: eventData.notifications,
				editEventTimeToPublish: DateHelper.GetEventSchedulePublishTime()
			});


		}).finally(() => this.HidePopout());
	}

	ShowEditEvent(event) {
		this.ShowSpinner();
		let weekFirstDate = this.state.editEventWeekFirstDate;
		EventService.GetEventByWeek(event.id, weekFirstDate).then(res => res.json()).then(eventData =>{
			console.log(eventData.schedules);
			this.setState({
				editEvent: eventData,
				activePanel: "adminEditEvent",
				editEventSchedules: eventData.schedules,
				editEventHasSchedules: eventData.schedules.length != 0,
				editEventScheduleTemplates: eventData.scheduleTemplates,
				editEventImages: eventData.images,
				editEventNotifications: eventData.notifications,
				editEventTimeToPublish: DateHelper.GetEventSchedulePublishTime()
			});


		}).finally(() => this.HidePopout());
		// EventService.GetEvent(event.id).then(res => res.json()).then(eventData => {
		// 	if (eventData.applyRequest != null) {
		// 		eventData.applyRequest.applyDate = new Date(eventData.applyRequest.applyDate);
		// 	}
		// 	console.log(eventData.schedules);
		// 	this.setState({
		// 		editEvent: eventData,
		// 		activePanel: "adminEditEvent",
		// 		editEventSchedules: eventData.schedules,
		// 		editEventHasSchedules: eventData.schedules.length != 0,
		// 		editEventScheduleTemplates: eventData.scheduleTemplates,
		// 		editEventApplyRequest: eventData.applyRequest,
		// 		editSchedulesChangeRequests: eventData.scheduleChangeRequests,
		// 		editEventImages: eventData.images,
		// 		editEventNotifications: eventData.notifications,
		// 		editEventTimeToPublish: DateHelper.GetEventSchedulePublishTime()
		// 	})

		// }).finally(() => this.HidePopout());
	}

	AddEventScheduleTemplateTime = (scheduleIndex) => {
		let scheduleTemplates = this.state.editEventScheduleTemplates;
		let userId = AuthService.GetCurrentUserValue().UserId;
		let newTime = {
			scheduleId: scheduleTemplates[scheduleIndex].scheduleId,
			eventId: this.state.editEvent.id,
			dayOfWeek: scheduleTemplates[scheduleIndex].dayOfWeek,
			time: "15:00",
			responsible: { "userId": userId }
		}
		scheduleTemplates[scheduleIndex].times.push(newTime);
		this.setState({ editEventScheduleTemplates: scheduleTemplates });
	}

	DeleteEventScheduleTemplateTime = (scheduleIndex, timeIndex) => {
		let scheduleTemplates = this.state.editEventScheduleTemplates;
		scheduleTemplates[scheduleIndex].times.splice(timeIndex, 1);
		if (scheduleTemplates[scheduleIndex].times.length == 0) {
			scheduleTemplates[scheduleIndex].isActive = false;
		}
		this.setState({ editEventScheduleTemplates: scheduleTemplates });
	}

	// This method check do we need to enable CreateApplyRequest button or disable it according to some values
	ShouldDisableApplyRequest = () => {
		return this.state.editEventApplyRequest ||
			this.state.editEventHasSchedules;
	}

	// this mehtod saves apply request and then notify users about the new request.
	CreateApplyRequest = () => {
		 var checkTime = DateHelper.TimeToDate(DateHelper.GetEventSchedulePublishTime());
		 var applyTime = DateHelper.TimeToDate(this.state.editEventTimeToPublish);
		 if (checkTime > applyTime){
		 	this.OpenNotificationAlert(`Пожалуйста, обратите внимание, что время публикации должно быть минимум в ${DateHelper.GetEventSchedulePublishTime()} по серверному времени!`)
		 	return;
		 }

		this.ShowSpinner();
		let user = AuthService.GetCurrentUserValue();
		//var applyTime = new Date();
		//applyTime.setMinutes(applyTime.getMinutes());

		let applyRequestInfo = {
			eventId: this.state.editEvent.id,
			//responsibleUserId: this.state.editEvent.responsibleUser.userId,
			responsibleUserId: user.UserId,
			applyDate: applyTime,
		}
		let errorResponseMessage = "";
		let canCreateRequest = true;

		//if there is already schedule, then don't send anything but notify user that schedule exists 
		if (this.state.editEventSchedules.length != 0) {
			// you already have schedule for current week

			if (applyTime.getDay() != 0) {

				let notifiationMessage = 'Расписание на эту неделю уже имеется. Публикция нового расписания будет доступна в воскресенье или дождитесь следующей недели.';
				this.OpenNotificationAlert(notifiationMessage)
				canCreateRequest = false;
			}
			else {

				if (this.state.editEventNextWeekSchedules.length != 0) {
					let notificationMessage = 'Расписание на cлед неделю уже имеется. Публикция нового расписания будет доступна с воскресенья следующей недели.';
					this.OpenNotificationAlert(notificationMessage)
					canCreateRequest = false;
				}
			}
		}

		if (canCreateRequest) {

			SchedulesService.SaveEventScheduleTemplates(this.state.editEvent.id, this.state.editEventScheduleTemplates).then(() => {

				//SchedulesService.ApplySchedule(this.state.editEvent.id, applyInfo)
				EventService.CreateApplyRequest(applyRequestInfo.eventId, applyRequestInfo).then(() => {
					//console.log(applyRequestInfo);
					this.setState({ editEventApplyRequest: applyRequestInfo });
				}).catch((errorMessage) => { errorResponseMessage = errorMessage })
			})

				.catch((errorMessage) => { errorResponseMessage = errorMessage })
				.finally(() => this.HidePopout());
		}
	}

	// Cancels the Apply Request 
	CancelApplyRequest = () => {
		this.ShowSpinner();
		EventService.CancelApplyRequest(this.state.editEvent.id, this.state.editEventApplyRequest).then(() => {
			this.setState({ editEventApplyRequest: null });
		}
		).catch(() => { }).finally(() => this.HidePopout());;
	};

	//This method saves and applies schedule directly without notification 
	//Not used anymore 
	ApplyEventSchedules = () => {
		//prepare the templates and send to Server 
		let applyInfo = {
			eventId: this.state.editEvent.id
		}

		this.ShowSpinner();

		SchedulesService.SaveEventScheduleTemplates(this.state.editEvent.id, this.state.editEventScheduleTemplates).then(() => {

			SchedulesService.ApplySchedule(this.state.editEvent.id, applyInfo)
		})


			.catch(err => console.log(err))
			.finally(() => this.HidePopout())
	};

	SaveEventScheduleTemplates = () => {
		//prepare the templates and send to Server 
		let currentSchedules = this.state.editEventScheduleTemplates
		this.ShowSpinner();
		SchedulesService.SaveEventScheduleTemplates(this.state.editEvent.id, currentSchedules)
			.then()
			.catch(err => console.log(err))
			.finally(() => this.HidePopout())

	};

	DeleteEventScheduleTemplate = scheduleTemplateIndex => {
		let eventId = this.state.editEvent.id;
		let scheduleTemplateId = this.state.editEventScheduleTemplates[scheduleTemplateIndex].id;
		this.ShowSpinner();

		SchedulesService.DeleteEventScheduleTemplate(eventId, scheduleTemplateId).then(() => {
			let schedules = this.state.editEventScheduleTemplates;
			schedules.splice(scheduleTemplateIndex, 1);
			this.setState({ editEventScheduleTemplates: schedules })
		})
			.catch(
				(err) => { console.log(err); }
			)
			.finally(this.HidePopout());
	};



	DeleteEventImage = imageIndex => {

		let images = this.state.editEventImages;
		let imageId = images[imageIndex].imageId;
		let eventId = images[imageIndex].eventId;
		this.ShowSpinner();
		ImagesService.DeleteEventImage(eventId, imageId).then(() => {
			let images = this.state.editEventImages;
			images.splice(imageIndex, 1);
			this.setState({ editEventImages: images })
		}).finally(() => this.HidePopout());
	};

	DeleteUserImage = imageIndex => {
		let images = this.state.editUserImages;
		let imageId = images[imageIndex].imageId;
		let userId = images[imageIndex].userId;
		this.ShowSpinner();
		ImagesService.DeleteUserImage(userId, imageId).then(() => {
			let images = this.state.editUserImages;
			images.splice(imageIndex, 1);
			this.setState({ editUserImages: images })
		}).finally(() => this.HidePopout());
	};

	OpenUsersConfigPanel = async () => {
		this.ShowSpinner();
		await UserService.GetUsers().then(async (res) => res.json()).then(async (users) => {
			//console.log(users);
			this.setState({ activePanel: AdminPanelsEnum.AdminUsersConfig, usersList: users });
		}


		).finally(() => this.HidePopout());
	};


	OpenAddUserPanel = async () => {
		this.setState({ activePanel: AdminPanelsEnum.AdminAddUser, newUser: emptyNewUser });
	}

	OpenEditUserPanel = async (user) => {

		this.ShowSpinner();
		if (user.UserId == undefined) {
			user.UserId = user.userId;
		}
		//let currentUser = AuthService.GetCurrentUserValue();
		await UserService.GetUser(user.UserId).then(async (res) => res.json()).then(async (user) => {

			this.setState({
				activePanel: AdminPanelsEnum.AdminAccountConfig,
				editUser: user,
				editUserImages: user.images
			})
		}).finally(() => this.HidePopout());
	};

	OpenAddEventPanel = async () => {
		this.setState({
			activePanel: 'addEventPanel',
			editEvent: emptyEventModel
		})
	}

	CreateNewUser = async () => {
		this.ShowSpinner();
		let userModel = this.state.newUser;
		await UserService.CreateUser(userModel).then((res) => {
			if (res.status === 500) {

				return res.text() // return the result of the inner promise, which is an error
					.then((text) => {
						//console.log(text);
						this.ShowSnackBar(text, true);
						//throw new ServerException(message, stackTrace);
					});
			} else {
				this.setState({ activePanel: AdminPanelsEnum.UserCreated })
				//console.log
				//return res.json();
			}

		}).catch((e) => {
			console.log(e);
			this.ShowSnackBar("Ошибка создания пользователя.");
		}).finally(() => this.HidePopout());
	}

	UpdateUser = async () => {
		this.ShowSpinner();
		let userModel = this.state.editUser;
		userModel.images = this.state.editUserImages;
		await UserService.UpdateUser(userModel).then(() => {
			this.setState({ activePanel: "adminMainPanel" })

		}).finally(() => this.HidePopout());
	}

	AddEventNotification = async () => {
		let user = AuthService.GetCurrentUserValue();
		let event = this.state.editEvent;
		let newNotification = {
			notificationId: 0,
			eventId: event.id,
			userId: user.UserId,
			createDate: new Date(),
			notificationText: this.state.editEventNewNotificationText
		}

		this.ShowSpinner();
		await EventService.AddEventNotification(event.id, newNotification).then((res) => res.json()).then((result) => {

			let notifications = this.state.editEventNotifications;
			notifications.push(result);
			this.setState({
				editEventNewNotificationText: "",
				editEventNotifications: notifications
			});
		}).catch(err => console.log(err)).finally(() => this.HidePopout());
	};

	DeleteEventNotification = async (notificationIndex) => {
		let notificationToDelete = this.state.editEventNotifications[notificationIndex];
		let eventId = notificationToDelete.eventId;
		let notificationId = notificationToDelete.notificationId;
		this.ShowSpinner();
		await EventService.DeleteEventNotification(eventId, notificationId).then(() => {
			let eventNotifications = this.state.editEventNotifications;
			eventNotifications.splice(notificationIndex, 1);
			this.setState({ editEventNotifications: eventNotifications })
		}).finally(() => {
			this.HidePopout();
		})
	}

	CreateEvent = async () => {
		this.ShowSpinner();
		let eventToCreate = this.state.editEvent;

		// TODO - check that the model is valid On UI
		let currentUser = AuthService.GetCurrentUserValue();
		eventToCreate.responsibleUser.userId = currentUser.UserId;
		//console.log(currentUser);
		//console.log(eventToCreate);

		await EventService.CreateEvent(eventToCreate).then(() => {

			EventService.GetUserEvents(currentUser.UserId).then(res => res.json()).then(events => {
				this.setState(
					{
						userEvents: events,
						activePanel: "adminEventsConfig"
					}
				);
			})
				.catch(() => { console.log("AdminPage the error during response to enpoint api/events") });


		}).finally(() => {
			this.HidePopout();
		});
		//add new values and send it to Server to create the new event

	}

	UpdateEvent = async () => {
		this.ShowSpinner();
		let eventToUpdate = this.state.editEvent;


		await EventService.UpdateEvent(eventToUpdate).then(() => {

			this.setState({ activePanel: "adminEventsConfig" })

		}).finally(() => this.HidePopout());
	}

	IsEditEventValid = () => {
		let editEvent = this.state.editEvent;
		return !editEvent.price || !editEvent.description || !editEvent.coordinateY || !editEvent.coordinateX || !editEvent.address || !editEvent.title
	}



	onMapClick = (e) => {
		let coords = e.get("coords");
		//console.log(coords);
		this.setState(prevState => ({
			editEvent: { ...prevState.editEvent, coordinateX: coords[0], coordinateY: coords[1] }
		}))
	};

	OnPlacemarkDragEnd = (e) => {

		let coords = e.get('target').geometry.getCoordinates();
		//console.log(coords);
		this.setState(prevState => ({
			editEvent: { ...prevState.editEvent, coordinateX: coords[0], coordinateY: coords[1] }
		}))
	}

	render() {

		return (
			<View id={this.props.id} popout={this.state.popout} activePanel={this.state.activePanel}>


				<Panel id={AdminPanelsEnum.AdminLoginPanel}>
					<PanelHeader>Панель ведущего/администратора</PanelHeader>
					<div className="panel-base page-block">
						{/* <div className="info-section"><h1>Добрый день!</h1>
						Панель ведущего , на данный момент, доступна только для зарегистрированных пользователей Вконтакте со статусом ведущего.
						Чуть позже мы добавим возможность регистрации и для других пользователей.
						Спасибо, что пользуетесь приложением А-гуля.
					</div> */}
						<div className="panel-body">
							<h1 className="text-center">Введите ваш логин и пароль для входа!</h1>
							<div className="centered login-section page-block">
								<div className="login-element"><input type="text" placeholder="Логин" name="usernameValue" value={this.state.usernameValue} onChange={this.OnChange}></input></div>
								<div className="login-element"><input type="password" name="passwordValue" placeholder="Пароль" value={this.state.passwordValue} onChange={this.OnChange}></input></div>
								<Button onClick={this.VerifyPassword} >Войти</Button>

							</div>
						</div>

					</div>
				</Panel>

				<Panel id={AdminPanelsEnum.AdminMainPanel}>
					<PanelHeader right={<Icon28DoorArrowRightOutline onClick={this.Logout} />}>Панель ведущего</PanelHeader>
					<Group>
						<Header mode="secondary">Меню</Header>
						<SimpleCell className="pointer" onClick={() => this.OpenEditUserPanel(AuthService.GetCurrentUserValue())} expandable before={<Icon28UserOutline />}>Аккаунт</SimpleCell>
						<SimpleCell className="pointer" onClick={() => this.setState({ activePanel: AdminPanelsEnum.AdminEventsConfig })} expandable before={<Icon28ListOutline />}>Площадки</SimpleCell>
						{this.state.isUserAdmin &&
							<SimpleCell className="pointer" onClick={this.OpenUsersConfigPanel} expandable before={<Icon28UsersOutline />}>Пользователи</SimpleCell>}
					</Group>
				</Panel>

				<Panel id="adminEventsConfig">
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'adminMainPanel' })} />}>Площадки</PanelHeader>
					{this.state.userEvents.map(x => <SimpleCell className="cell-onhover pointer" onClick={() => this.ShowEditEvent(x)}>{x.title}</SimpleCell>)}
					<Button size="xl" className="pointer bottom" onClick={() => this.OpenAddEventPanel()}>Добавить площадку</Button>
				</Panel>

				<Panel id="adminAccountConfig">
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'adminMainPanel' })} />}>Аккаунт</PanelHeader>
					{this.state.editUser &&
						<div>
							<FormLayout>
								<Input
									type="Имя"
									top="Имя"
									name="name"
									value={this.state.editUser.name}
									readOnly
								// onChange={this.editEventChangeHandler}
								/>

								<Input
									type="Фамилия"
									top="Фамилия"
									name="surname"
									value={this.state.editUser.surname}
									readOnly
								// onChange={this.editEventChangeHandler}
								/>

								<Input
									type="Отчество"
									top="Отчество"
									name="middlename"
									value={this.state.editUser.middlename}
									readOnly
								// onChange={this.editEventChangeHandler}
								/>

								<Input
									type="Email"
									top="Email"
									name="email"
									value={this.state.editUser.email}
									onChange={this.editUserChangeHandler}
								/>

								<Input
									type="Телефон"
									top="Телефон для связи родителей"
									name="tel"
									value={this.state.editUser.tel}
									onChange={this.editUserChangeHandler}
								/>

								<Input
									type="Город"
									top="Город"
									name="city"
									value={this.state.editUser.city}
									onChange={this.editUserChangeHandler}
								/>

								<Input
									type="Адрес"
									top="Адрес"
									name="address"
									value={this.state.editUser.address}
									onChange={this.editUserChangeHandler}
								/>

								<Textarea
									type="О себе"
									top="О себе"
									name="description"
									value={this.state.editUser.description}
									onChange={this.editUserChangeHandler}
								/>

								<Group separator="hide" >
									<Header mode="secondary">Изображения</Header>
									<CardScroll>
										{this.state.editUserImages && this.state.editUserImages
											.map((imageObj, imageIndex) =>
												<Card key={imageIndex} size="s">


													<img src={imageObj.fileName} style={{ width: 144, height: 96 }}></img>
													<span className="pointer centered" onClick={() => this.OpenImageDeletionPopout(imageIndex, this.DeleteUserImage)}>{<Icon28DeleteOutline />}</span>


												</Card>)
										}
										<Card size="s">
											<div className="pointer" style={{ width: 144, height: 96 }} > <input type="file" name="file" onChange={this.AddUserImageHandler} />Добавить картинку</div>
										</Card>
									</CardScroll>
								</Group>

							</FormLayout>
							<Button size="xl" className="pointer" onClick={this.UpdateUser}>Сохранить</Button>
						</div>
					}
				</Panel>




				<Panel id="adminEditEventMain">
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'adminEditEvent' })} />}>{this.state.editEvent?.address}</PanelHeader>
					<Icon28EditOutline className="pointer" onClick={this.ToggleEditEventMode} />

					<FormLayout>

						<Input
							type="Адрес"
							top="Адрес"
							name="address"
							value={this.state.editEvent?.address}
							status={this.state.editEvent?.address ? 'valid' : 'error'}
							onChange={this.editEventChangeHandler}
							readOnly={!this.state.isEditMode}
						/>

						<Div top="Местоположение">
							<table style={{ border: "none" }}>
								<tr><td> Координата X:<Input
									type="координата X"
									bottom="Координата X"
									name="coordinateX"
									status={this.state.editEvent?.coordinateX ? 'valid' : 'error'}
									value={this.state.editEvent?.coordinateX}
									onChange={this.editEventChangeHandler}
									readOnly={!this.state.isEditMode}

								/></td><td>Координата Y:<Input
									type="координата Y"
									top="Координата Y"
									name="coordinateY"
									status={this.state.editEvent?.coordinateY ? 'valid' : 'error'}
									value={this.state.editEvent?.coordinateY}
									onChange={this.editEventChangeHandler}
									readOnly={!this.state.isEditMode}
								/></td></tr>
								<tr><td colSpan="2">
									<YMaps>
										<Map
											state={{
												center: [this.state.editEvent?.coordinateX, this.state.editEvent?.coordinateY],
												zoom: 11,
												controls: ['zoomControl', 'fullscreenControl']
											}}
											className="events-map"
											modules={['control.ZoomControl', 'control.FullscreenControl']}
											onClick={this.state.isEditMode ? this.onMapClick : ""}
										>
											<Placemark
												geometry={{
													type: 'Point',
													coordinates: [this.state.editEvent?.coordinateX, this.state.editEvent?.coordinateY]
												}}
												options={{
													preset: 'islands#redDotIcon',
													draggable: this.state.isEditMode
												}}
												onDragEnd={this.OnPlacemarkDragEnd} />
										</Map>
									</YMaps>

								</td></tr>
							</table>
						</Div>
						<Textarea top="Описание площадки"
							placeholder="Описание площадки"
							name="description"
							value={this.state.editEvent?.description}
							onChange={this.editEventChangeHandler}
							readOnly={!this.state.isEditMode}
						/>
						<Input
							type="Информация о цене"
							top="информация о цене"
							name="price"
							value={this.state.editEvent?.price}
							onChange={this.editEventChangeHandler}
							readOnly={!this.state.isEditMode}
						/>
					</FormLayout>
					<Button size="xl" className="pointer edit-event-button" onClick={this.UpdateEvent}>Сохранить</Button>
				</Panel>





				<Panel id="adminEditEventImages">
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'adminEditEvent' })} />}>{this.state.editEvent?.address}</PanelHeader>
					<Group separator="hide" >
						<Header mode="secondary">Изображения</Header>
						<CardScroll>
							{this.state.editEventImages && this.state.editEventImages
								.map((imageObj, imageIndex) =>
									<Card key={imageIndex} size="s">


										<img src={imageObj.fileName} style={{ width: 144, height: 96 }}></img>
										<span className="pointer centered" onClick={() => this.OpenImageDeletionPopout(imageIndex, this.DeleteEventImage)}>{<Icon28DeleteOutline />}</span>
									</Card>)
							}
							<Card size="s">
								<div className="pointer" style={{ width: 144, height: 96 }} > <input type="file" name="file" onChange={this.AddEventImageHandler} />Добавить картинку</div>
							</Card>
						</CardScroll>
					</Group>
				</Panel>




				<Panel id="adminEditEventSchedule">
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'adminEditEvent' })} />}>{this.state.editEvent?.address}</PanelHeader>
					<Group>
						<Header mode="secondary">Расписание прогулок</Header>
						<Header mode="secondary">на неделе с {this.state.editEventWeekFirstDate.formatDDMM()} по {this.state.editEventWeekLastDate.formatDDMM()}</Header>
<Button onClick={this.DecreaseWeekToCheck}>Пред неделя</Button>
<Button onClick={this.IncreaseWeekToCheck}>След неделя</Button>
						<div>
							<table>
								<thead>

									<tr><th>День недели</th><th>Время</th><th>Цена</th><th></th></tr>
								</thead>
								<tbody>
									{this.state.editEventScheduleTemplates.map((x, scheduleIndex) =>

										<tr key={scheduleIndex} >
											<td className="vertical-top border-bottom"> <Checkbox onChange={(e) => { this.HandleScheduleCheck(scheduleIndex) }} defaultChecked={x.isActive} disabled={x.times.length == 0 || this.state.editEventApplyRequest != null}>{DateHelper.GetDayOfWeekFullName(x.dayOfWeek)}</Checkbox></td>
											<td className="border-bottom" disabled={this.state.editEventApplyRequest}>
												{x.times && x.times.map((timeValue, timeIndex) => <div><Input type="time" className="inline-block"
													onChange={(event) => this.EditScheduleTemplateTimeChangeHandler(event, scheduleIndex, timeIndex)}
													value={timeValue.time} disabled={this.state.editEventApplyRequest}></Input>

													{/* <span className="pointer inline-block" onClick={() => this.DeleteEventScheduleTemplateTime(scheduleIndex, timeIndex)} ><Icon20Cancel></Icon20Cancel></span> */}
													<Button mode="tertiary" onClick={() => this.DeleteEventScheduleTemplateTime(scheduleIndex, timeIndex)} disabled={this.state.editEventApplyRequest} ><Icon20Cancel></Icon20Cancel></Button>
												</div>
												)}
											</td >
											<td className="border-bottom">
												<Input className="inline-block" onChange={(priceInput) => this.HandleSchedulePrice(scheduleIndex, priceInput)} value={x.price}></Input>
												{/* {timeValue.responsible.name} 
			{timeValue.responsible.surname}  */}
											</td>
											<td className="border-bottom" >
												{/* <Button className="pointer" mode="tertiary" onClick={() => this.AddEventScheduleTemplateTime(scheduleIndex)}>+</Button> */} 
												<Button className="pointer" mode="secondary" onClick={() => this.AddEventScheduleTemplateTime(scheduleIndex)} disabled={this.state.editEventApplyRequest}><Icon20Add></Icon20Add></Button>
											</td>
										</tr>
									)}
								</tbody>
							</table>
							<p className="info-section">Расписание на неделю: <span className="text-success" hidden={!this.state.editEventHasSchedules}>опубликовано!</span><span className="text-danger" hidden={this.state.editEventHasSchedules}> не опубликовано!</span></p>
							<p>
								<p>Серверное время - {this.state.serverTime}</p>
								{this.state.editEventApplyRequest != null
								&& <div className="info-section"><span> Расписание будет опубликовано в {DateHelper.SetZeroToNumber(this.state.editEventApplyRequest.applyDate.getUTCHours())}:{DateHelper.SetZeroToNumber(this.state.editEventApplyRequest.applyDate.getUTCMinutes())} по серверному времени.  <span className="cancel-link" onClick={this.CancelApplyRequest}>Отменить</span> публикацию расписания </span></div>}
								{this.state.editEventApplyRequest == null
								&& <div> Время публикации - <Input className="inline-block" onChange={(event) => this.EditSchedulePublishTimeChangeHandler(event)} type="time" value={this.state.editEventTimeToPublish}></Input> по серверному времени ( не меньше получаса от текущего времени!)</div> }								
								{/* <span hidden={this.state.editEventHasSchedules}>на текущую неделю:</span> */}
							</p>
							
							<Button size="xl" mode="commerce" onClick={this.CreateApplyRequest} disabled={this.ShouldDisableApplyRequest()}>Сохранить и Опубликовать </Button> 
						</div>
					</Group>
				</Panel>




				<Panel id="adminEditEventMessages">
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'adminEditEvent' })} />}>{this.state.editEvent?.address}</PanelHeader>
					<Group separator="hide" >
						<Header mode="secondary">Сообщения</Header>
						{this.state.editEventNotifications.map((x, notificationIndex) =>

							<div className="notification-box">

								<div className="notification-box-text-section">

									<div class="notification-box-header">
										{x.createDate}
									</div>
									<div className="notification-box-body"><span className="inline-block">{x.notificationText}</span></div>

								</div>

								<div className="notification-box-icon-section"><span className="pointer" onClick={() => { this.DeleteEventNotification(notificationIndex) }}><Icon56DeleteOutlineIos /></span></div>


							</div>

						)}
						{/* добавление сообщений */}
						<div className="notification-box">

							<div className="notification-box-text-section">

								<div className="notification-box-header">
									Введите новое сообщение
								</div>
								<div className="notification-box-body"><span className="inline-block"><Input
									type="сообщение от ведущего"
									top="сообщение от ведущего"
									name="editEventNewNotificationText"
									value={this.state.editEventNewNotificationText}
									onChange={this.OnChange}
								/></span></div>

							</div>

							<div className="notification-box-icon-section"><span className="pointer" onClick={this.AddEventNotification}><Icon56AddCircleOutline /></span></div>

						</div>
					</Group>

				</Panel>




				<Panel id="adminEditEvent">
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'adminEventsConfig', editEvent: null })} />}>Настройка площадки</PanelHeader>
					{this.state.editEvent && <div>
						<h2 className="text-center">{this.state.editEvent.address}</h2>
						<SimpleCell className="pointer text-center" onClick={() => this.setState({ activePanel: 'adminEditEventMain' })}>Основная информация </SimpleCell>
						<SimpleCell className="pointer text-center" onClick={() => this.setState({ activePanel: 'adminEditEventImages' })}>Изображения</SimpleCell>
						<SimpleCell className="pointer text-center" onClick={() => this.setState({ activePanel: 'adminEditEventSchedule' })}>Расписание прогулок</SimpleCell>
						<SimpleCell className="pointer text-center" onClick={() => this.setState({ activePanel: 'adminEditEventMessages', editEventTimeToPublish:DateHelper.GetEventSchedulePublishTime()})}>Сообщения</SimpleCell>

						{/* <SimpleCell className="pointer text-center collapse-button hidden" onClick={this.ToggleVisible}>Сообщения</SimpleCell>
							<div class="collapse-section">

								


							</div> */}
					</div>


					}
				</Panel>

				<Panel id="addEventPanel">
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'adminEventsConfig' })} />}>Добавить новую прогулку</PanelHeader>

					{this.state.editEvent &&
						<div>
							<SimpleCell >Основная информация</SimpleCell>
							<div>


								<FormLayout>
									<Input
										type="Название"
										top="Название площадки"
										name="title"
										value={this.state.editEvent.title}
										status={this.state.editEvent.title ? 'valid' : 'error'}
										onChange={this.editEventChangeHandler}
									/>

									<Input
										type="Адрес"
										top="Адрес"
										name="address"
										value={this.state.editEvent.address}
										status={this.state.editEvent.address ? 'valid' : 'error'}
										onChange={this.editEventChangeHandler}
									/>

									<Div top="Местоположение">
										<table style={{ border: "none" }}>
											<tr><td> Координата X:<Input
												type="координата X"
												bottom="Координата X"
												name="coordinateX"
												value={this.state.editEvent.coordinateX}
												status={this.state.editEvent.coordinateX ? 'valid' : 'error'}
												onChange={this.editEventChangeHandler}


											/></td><td>Координата Y:<Input
												type="координата Y"
												top="Координата Y"
												name="coordinateY"
												value={this.state.editEvent.coordinateY}
												status={this.state.editEvent.coordinateY ? 'valid' : 'error'}
												onChange={this.editEventChangeHandler}

											/></td></tr>
											<tr ><td colSpan="2">


												<YMaps>
													<Map
														state={{
															center: [this.state.editEvent.coordinateX, this.state.editEvent.coordinateY],
															zoom: 11,
															controls: ['zoomControl', 'fullscreenControl']
														}}
														className="events-map"
														modules={['control.ZoomControl', 'control.FullscreenControl']}
														onClick={this.onMapClick}
													>
														<Placemark
															geometry={{
																type: 'Point',
																coordinates: [this.state.editEvent.coordinateX, this.state.editEvent.coordinateY]
															}}
															options={{
																preset: 'islands#redDotIcon',
																draggable: true
															}}
															onDragEnd={this.OnPlacemarkDragEnd} />
													</Map>
												</YMaps>

												{/* <YMaps 
											query={{ apikey: "your api key" }}
											>
												Проверка
												<Map className="events-map"
              state={{
                center: [59.9661, 30.3113],
                zoom: 11,
                controls: ['zoomControl', 'fullscreenControl']
              }}
			  modules={['control.ZoomControl', 'control.FullscreenControl']}
			  onClick={this.onMapClick}
            ></Map>
			<Map
         // modules={["Placemark", "geoObject.addon.balloon"]}
        
          //onClick={this.onMapClick}
		  state={{
			center: [59.9661, 30.3113],
			zoom: 11,
			controls: ['zoomControl', 'fullscreenControl']
		  }}
        />
		
    
      </YMaps> */}

											</td></tr>
										</table>
									</Div>
									<Textarea top="Описание площадки"
										placeholder="Описание площадки"
										name="description"
										value={this.state.editEvent.description}
										status={this.state.editEvent.description ? 'valid' : 'error'}
										onChange={this.editEventChangeHandler} />
									<Input
										type="Информация о цене"
										top="информация о цене"
										name="price"
										value={this.state.editEvent.price}
										status={this.state.editEvent.price ? 'valid' : 'error'}
										onChange={this.editEventChangeHandler}
									/>

								</FormLayout>
								<Button size="xl" className="pointer edit-event-button" disabled={this.IsEditEventValid()} onClick={this.CreateEvent}>Создать прогулку</Button>
							</div>
						</div>}
				</Panel>

				{/* <AdminUsersConfigPanel HeaderBackLink={()=>this.HeaderBackLink(AdminPanelsEnum.AdminMainPanel)} usersList={this.state.usersList}>

</AdminUsersConfigPanel> */}

				<Panel id={AdminPanelsEnum.AdminUsersConfig}>
					<PanelHeader
						left={<PanelHeaderBack onClick={() => this.setState({ activePanel: AdminPanelsEnum.AdminMainPanel })} />}>Пользователи</PanelHeader>
					{this.state.usersList.map((x) =>
						<SimpleCell className="cell-onhover pointer" onClick={() => this.OpenEditUserPanel(x)}>{x.userId}: {x.name} {x.surname}</SimpleCell>)}
					<Button size="xl" className="pointer bottom" onClick={this.OpenAddUserPanel}>Добавить пользователя</Button>
				</Panel>


				<Panel id={AdminPanelsEnum.AdminAddUser}>
					<PanelHeader left={
						<PanelHeaderBack onClick={() => this.setState({ activePanel: AdminPanelsEnum.AdminMainPanel })} />}>
						Создать нового пользователя
					</PanelHeader>
					<FormLayout>

						<Input
							type="Username"
							top="Username"
							name="username"
							value={this.state.newUser.username}
							status={this.state.newUser.username ? 'valid' : 'error'}
							onChange={this.newUserChangeHandler}
						/>

						<Input
							type="name"
							top="Имя"
							name="name"
							value={this.state.newUser.name}
							status={this.state.newUser.name ? 'valid' : 'error'}
							onChange={this.newUserChangeHandler}
						/>
						<Input
							type="SureName"
							top="Фамилия"
							name="surname"
							value={this.state.newUser.surname}
							status={this.state.newUser.surname ? 'valid' : 'error'}
							onChange={this.newUserChangeHandler}
						/>
						<Input
							type="Email"
							top="почта"
							name="email"
							value={this.state.newUser.email}
							status={this.state.newUser.email ? 'valid' : 'error'}
							onChange={this.newUserChangeHandler}
						/>

					</FormLayout>
					<Button size="xl" className="pointer" onClick={this.CreateNewUser}>Создать</Button>
					{this.state.snackbar}
				</Panel>

				<Panel id={AdminPanelsEnum.UserCreated}>
					Пользователь был успешно созднан!
					<Button className="pointer" onClick={() => { this.setState({ activePanel: AdminPanelsEnum.AdminUsersConfig }) }}>Вернуться в меню</Button>
				</Panel>

			</View>
		)
	}
}

export default AdminPanelView;