const basicEndpointUrl = window.location.origin;

const UserSignsService = {
    SignToEventSchedule,
    UnsignFromEventSchedule,
    GetUserScheduleSigns
    }

   async function SignToEventSchedule(signRequest){
      const requestOptions = {
          method: 'PUT',
//          headers: AuthHeader(),
          body: JSON.stringify(signRequest)
      };
     
      return fetch(`${basicEndpointUrl}/api/usersigns`, requestOptions);
  }
  
  async function UnsignFromEventSchedule(signRequest){
      const requestOptions = {
          method: 'DELETE',
//          headers: AuthHeader(),
          body: JSON.stringify(signRequest)
      };
     
      return fetch(`${basicEndpointUrl}/api/usersigns/`, requestOptions);
  }
  
 async function GetUserScheduleSigns(signRequest){
      const requestOptions = {
          method: 'POST',
//          headers: AuthHeader(),
          body: JSON.stringify(signRequest)
      };
     
      return fetch(`${basicEndpointUrl}/api/usersigns`, requestOptions);
  }

export default UserSignsService;