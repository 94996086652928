//import { BehaviorSubject } from 'rxjs';

import { handleResponse } from '../helpers/handleResponse';
import AuthHeader from '../helpers/auth-header';

 //currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
 const basicEndpointUrl = window.location.origin;

const AuthService = {
    Login,
    Logout,
    CheckAuth,
    UpdateUserData,
    //currentUser: currentUserSubject.asObservable(),
    //get currentUserValue () { return currentUserSubject.value }
    GetCurrentUserValue () { return JSON.parse(localStorage.getItem('currentUser'))},
    IsCurrentUserAdmin
};

function Login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${basicEndpointUrl}/api/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
       
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            //currentUserSubject.next(user);
            return user;
        }).catch(ex =>{ return Promise.reject(ex) ; });
}

function UpdateUserData(userModel){

}

function CheckAuth(){
    const requestOptions = { method: 'GET', headers: AuthHeader() };
    return fetch(`${basicEndpointUrl}/api/eventsFull`, requestOptions).then(handleResponse).catch(() => console.log("catched an unauth error"));
}

function Logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    //currentUserSubject.next(null);
}

function IsCurrentUserAdmin(){
    let currentUser = this.GetCurrentUserValue();
    if (currentUser.UserTypeId == 99) {
        return true;
    }
    return false;
}

export default AuthService