import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { YMaps, Map, ObjectManager } from 'react-yandex-maps';
//import ActivePlacemark from './ActivePlacemark';

//import PropTypes from 'prop-types';
//import { platform, IOS, List } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import Alert from '@vkontakte/vkui/dist/components/Alert/Alert';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderBack from '@vkontakte/vkui/dist/components/PanelHeaderBack/PanelHeaderBack';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Tabs from '@vkontakte/vkui/dist/components/Tabs/Tabs';
import Banner from '@vkontakte/vkui/dist/components/Banner/Banner';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import SimpleCell from '@vkontakte/vkui/dist/components/Cell/Cell';
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem';
import EventScheduleTemplate from './EventScheduleTemplate'
import EventScheduleTemplateLight from './EventScheduleTemplateLight'
import Icon28FavoriteOutline from '@vkontakte/icons/dist/28/favorite_outline';
import Icon28favorite from '@vkontakte/icons/dist/28/favorite';
import Icon28NotificationOutline from '@vkontakte/icons/dist/28/notification';
import Icon28NotificationsOutline from '@vkontakte/icons/dist/28/notifications';
import Icon28DoorArrowLeftOutline from '@vkontakte/icons/dist/28/door_arrow_left_outline';
import Icon56InfoOutline from '@vkontakte/icons/dist/56/info_outline';
import Gallery from '@vkontakte/vkui/dist/components/Gallery/Gallery'
import ImageGallery from 'react-image-gallery';


//Services
import EventService from "../Services/EventService";
import UserService from "../Services/UserService";
import UserFavoritesService from "../Services/UserFavoritesService";
import UserSignsService from "../Services/UserSignsService";

import './events.css';
import './common.css';
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import VKservice from '../Services/VKservice';
import DateHelper from '../helpers/dates-helper';

//const osName = platform();

const warningGradient = 'linear-gradient(90deg, #ffb73d 0%, #ffa000 100%)';

const eventsTestArray = [{ "type": "Feature", "id": 0, "geometry": { "type": "Point", "coordinates": [59.9661, 30.3113] }, "properties": { "balloonContentHeader": "<font size=3><b><a target='_blank' href='https://yandex.ru'>Здесь может быть ваша ссылка</a></b></font>", "balloonContentBody": "<p>Ваше имя: <input name='login'></p><p><em>Телефон в формате 2xxx-xxx:</em>  <input></p><p><input type='submit' value='Отправить'></p>", "balloonContentFooter": "<font size=1>Информация предоставлена: </font> <strong>этим балуном</strong>", "clusterCaption": "<strong><s>Еще</s> одна</strong> метка", "hintContent": "<strong>Текст  <s>подсказки</s></strong>" } },
{ "type": "Feature", "id": 1, "geometry": { "type": "Point", "coordinates": [59.96, 30.31] }, "properties": { "balloonContentHeader": "<font size=3><b><a target='_blank' href='https://yandex.ru'>Здесь может быть ваша ссылка</a></b></font>", "balloonContentBody": "<p>Ваше имя: <input name='login'></p><p><em>Телефон в формате 2xxx-xxx:</em>  <input></p><p><input type='submit' value='Отправить'></p>", "balloonContentFooter": "<font size=1>Информация предоставлена: </font> <strong>этим балуном</strong>", "clusterCaption": "<strong><s>Еще</s> одна</strong> метка", "hintContent": "<strong>Текст  <s>подсказки</s></strong>" } },
{ "type": "Feature", "id": 2, "geometry": { "type": "Point", "coordinates": [59.9665, 30.3119] }, "properties": { "balloonContentHeader": "<font size=3><b><a target='_blank' href='https://yandex.ru'>Здесь может быть ваша ссылка</a></b></font>", "balloonContentBody": "<p>Ваше имя: <input name='login'></p><p><em>Телефон в формате 2xxx-xxx:</em>  <input></p><p><input type='submit' value='Отправить'></p>", "balloonContentFooter": "<font size=1>Информация предоставлена: </font> <strong>этим балуном</strong>", "clusterCaption": "<strong><s>Еще</s> одна</strong> метка", "hintContent": "<strong>Текст  <s>подсказки</s></strong>" } },
{ "type": "Feature", "id": 3, "geometry": { "type": "Point", "coordinates": [59.966, 30.3104] }, "properties": { "balloonContentHeader": "<font size=3><b><a target='_blank' href='https://yandex.ru'>Здесь может быть ваша ссылка</a></b></font>", "balloonContentBody": "<p>Ваше имя: <input name='login'></p><p><em>Телефон в формате 2xxx-xxx:</em>  <input></p><p><input type='submit' value='Отправить'></p>", "balloonContentFooter": "<font size=1>Информация предоставлена: </font> <strong>этим балуном</strong>", "clusterCaption": "<strong><s>Еще</s> одна</strong> метка", "hintContent": "<strong>Текст  <s>подсказки</s></strong>" } }];

//const propertieTest = {balloonContentHeader: "<font size=5><b><a target='_blank' href='https://yandex.ru'>Здесь может быть ваша ссылка</a></b></font>", hintContent: "<strong>Текст  <s>подсказки</s></strong>"}

const emptyUserModel = {
  "userId": 0,
  "name": "",
  "surname": "",
  "tel": "",
  "contacts": "",
  "photo": ""
}

const emptyScheduleModel = {
  "scheduleId": 0,
  "eventId": 0,
  "dayOfWeek": 0,
  "time": "00:00",
  "responsible": { emptyUserModel },
  "date": null
}

const emptyEventsModel = [{
  "id": 0,
  "address": "",
  "title": "",
  "coordinateX": 0.0,
  "coordinateY": 0.0,
  "contacts": "",
  "images": [],
  "responsible": { emptyUserModel },
  "schedule": [{ emptyScheduleModel }]
}
];

const emptyEventScheduleDayModel = {
  "price": 0,
  "tel": "",
  "responsible": { emptyUserModel },
  "times": []
}


let testNotifications = [{ notificationId: 1, creatorId: 2, eventId: 2, dateTime: Date.now(), text: "20 числа будет жарко , возьмите, пожалуйста зонты", }]

// function DefaultSrcValue (imageSrc) {
//   if (process.env.NODE_ENV === "development") {
//     let path = imageSrc.replace("../","");
//     return  "file:///D:/Coding/GulyaWeb/Backend/"+path;
//   }
//   else 
//   {
//     return imageSrc;
//   }

// } 


function setZeroToNumber(i) { let s = i + ""; let len = i.length || 2; while (s.length < len) s = "0" + s; return s; }

function GetDateByDayOfWeek(dayOfWeek) {
  let curr = new Date();
  let resultDate = new Date();
  let currentDayOfWeek = curr.getDay();
  if (currentDayOfWeek == 0) {
    currentDayOfWeek = 7;
  }
  resultDate.setDate(curr.getDate() - currentDayOfWeek + 1 + dayOfWeek);
  return `${setZeroToNumber(resultDate.getDate())}.${setZeroToNumber(resultDate.getMonth() + 1)}`
}


function FillScheduleTableTemplateDataFull(schedulesFromServer) {
  let baseScheduleModels = [
    { dayOfWeek: 1, dayTitle: `Пн ${GetDateByDayOfWeek(0)}`, dayTitleFull: "Понедельник", dateTitle: GetDateByDayOfWeek(0), eventSchedules: [] },
    { dayOfWeek: 2, dayTitle: `Вт ${GetDateByDayOfWeek(1)}`, dayTitleFull: "Вторник", dateTitle: GetDateByDayOfWeek(1), eventSchedules: [] },
    { dayOfWeek: 3, dayTitle: `Ср ${GetDateByDayOfWeek(2)}`, dayTitleFull: "Среда", dateTitle: GetDateByDayOfWeek(2), eventSchedules: [] },
    { dayOfWeek: 4, dayTitle: `Чт ${GetDateByDayOfWeek(3)}`, dayTitleFull: "Четверг", dateTitle: GetDateByDayOfWeek(3), eventSchedules: [] },
    { dayOfWeek: 5, dayTitle: `Пт ${GetDateByDayOfWeek(4)}`, dayTitleFull: "Пятница", dateTitle: GetDateByDayOfWeek(4), eventSchedules: [] },
    { dayOfWeek: 6, dayTitle: `Сб ${GetDateByDayOfWeek(5)}`, dayTitleFull: "Суббота", dateTitle: GetDateByDayOfWeek(5), eventSchedules: [] },
    { dayOfWeek: 7, dayTitle: `Вс ${GetDateByDayOfWeek(6)}`, dayTitleFull: "Воскресенье", dateTitle: GetDateByDayOfWeek(6), eventSchedules: [] },
  ];

  for (var i = 0; i <= baseScheduleModels.length - 1; i++) {
    let day = baseScheduleModels[i].dayOfWeek;
    for (var j = 0; j <= schedulesFromServer.length - 1; j++) {
      let eventSchedules = schedulesFromServer[j].schedules.find(x => x.dayOfWeek == day);
      if (eventSchedules != undefined && eventSchedules.times.length) {
        let eventSchedule = {
          eventId: schedulesFromServer[j].eventId,
          eventAddress: schedulesFromServer[j].address,
          eventPrice: schedulesFromServer[j].priceInfo,
          times: eventSchedules.times
        }
        baseScheduleModels[i].eventSchedules.push(eventSchedule);
      }
    }
  }

  return baseScheduleModels;
}


function FillScheduleTableTemplateData(schedulesFromServer) {
  let baseScheduleModels = [];

  for (var i = 0; i <= 6; i++) {

    let dayTitle = `${DateHelper.GetDayOfWeekShortName(i + 1)} ${GetDateByDayOfWeek(i)}`;
    let dayTitleFull = `${DateHelper.GetDayOfWeekFullName(i + 1)} ${GetDateByDayOfWeek(i)}`;
    let baseScheduleModel =
      { dayOfWeek: i + 1, dayTitle: dayTitle, dayTitleFull: dayTitleFull, responsible: {}, price: 0, times: [] };

    let foundSchedule = schedulesFromServer.find(x => x.dayOfWeek == i + 1);
    //console.log(foundSchedule);
    if (foundSchedule != undefined) {
      baseScheduleModel.price = foundSchedule.price;
      baseScheduleModel.times = foundSchedule.times;
      //baseScheduleModel.responsible = foundSchedule.responsible;
    }
    baseScheduleModels.push(baseScheduleModel);
  }

  return baseScheduleModels;



  // let baseScheduleModels = [
  //   { dayOfWeek: 1, dayTitle: `Пн ${GetDateByDayOfWeek(0)}`,responsible:{}, price:10, times: [] },
  //   { dayOfWeek: 2, dayTitle: `Вт ${GetDateByDayOfWeek(1)}`,responsible:{}, price:10, times: [] },
  //   { dayOfWeek: 3, dayTitle: `Ср ${GetDateByDayOfWeek(2)}`,responsible:{}, price:10, times: [] },
  //   { dayOfWeek: 4, dayTitle: `Чт ${GetDateByDayOfWeek(3)}`,responsible:{}, price:10, times: [] },
  //   { dayOfWeek: 5, dayTitle: `Пт ${GetDateByDayOfWeek(4)}`,responsible:{}, price:10, times: [] },
  //   { dayOfWeek: 6, dayTitle: `Сб ${GetDateByDayOfWeek(5)}`,responsible:{}, price:10, times: [] },
  //   { dayOfWeek: 7, dayTitle: `Вс ${GetDateByDayOfWeek(6)}`,responsible:{}, price:10, times: [] },
  // ];

  // for (var i = 0; i <= baseScheduleModels.length - 1; i++) {
  //   let day = baseScheduleModels[i].dayOfWeek;

  //   let foundSchedule = schedulesFromServer.find(x => x.dayOfWeek == day);
  //   if (foundSchedule != undefined) {
  //     baseScheduleModels[i].price = foundSchedule.price;
  //     baseScheduleModels[i].times = foundSchedule.times;
  //   }
  // }
  // //console.log(baseScheduleModels);
  // return baseScheduleModels;
}


class EventsView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      previousPanel: "",
      mainPanel: "eventsPanel",
      activeTab: "allevents",
      selectedEvent: {},
      selectedUser: {},
      selectedUserImages: [],
      selectedDay: emptyEventScheduleDayModel,
      search: "",
      popout: null,
      eventSchedules: [],
      eventImages: [],
      userFavoritesEvents: [],
      userFavoritesEventsSchedules: [],
      userScheduleTimeSigns: [],
      isFavorite: false,
      events: emptyEventsModel,
      subscribed: false,
      geoObjects: []
    };

    this.OpenAlert = this.OpenAlert.bind(this);
    this.OpenSignAlert = this.OpenSignAlert.bind(this);
    this.HidePopout = this.HidePopout.bind(this);
    this.OpenResponsibleProfilePanel = this.OpenResponsibleProfilePanel.bind(this);
    this.OpenScheduleDayInfo = this.OpenScheduleDayInfo.bind(this);
  };

  OpenAlert() {

    this.setState({
      popout:
        <Alert
          actionsLayout="vertical"
          actions={[

            {
              title: 'Закрыть',
              autoclose: true,
              mode: 'cancel'
            }]}
          onClose={this.HidePopout}
        >
          <h2>Необходимо согласие!</h2>
          <p>Для подписки на изменения расписания необходимо разрешить приложению отправлять сообщения от имени сообщества «А-Гуля — весёлые и оздоровительные прогулки».</p>
        </Alert>
    });
  }

  OpenSignAlert() {

    this.setState({
      popout:
        <Alert
          actionsLayout="vertical"
          actions={[
            {
              title: 'Продолжить без перехода',
              autoclose: true,
              mode: 'destructive',

            },
            {
              title: 'Зайти через Вконтакте',
              autoclose: true,
              mode: 'cancel',
              action: () => window.open("https://vk.com/app7491526", "_self")
            }]}
          onClose={this.HidePopout}
        >
          <h2>Необходима авторизация!</h2>
          <p>Для данного действия необходимо зайти в приложение через сайт Vkontakte.</p>
          <p>Спасибо!</p>
        </Alert>
    });
  }

  UpdateFavoriteData = async () => {
    VKservice.GetUserInfo().then(user => {
      if (user != null) {
        UserFavoritesService.GetUserFavoriteEvents(user).then(res => res.json()).then(favoriteEvents => {
          this.setState({ userFavoritesEvents: favoriteEvents });
        })
      }
    });
  }


  LoadFavoriteData = async (user) => {
    // I suppose we already have the userId from the props.vkUser ?
    if (user != null) {
      UserFavoritesService.GetUserFavoriteEvents(user).then(res => res.json()).then(favoriteEvents => {
        if (favoriteEvents.length > 0) {
          if (favoriteEvents.length == 1) {
            let event = favoriteEvents[0];
            this.setState({ userFavoritesEvents: favoriteEvents });
            this.OpenSchedulePanelByEventObject(event)
          }
          else {
            let schedules = FillScheduleTableTemplateDataFull(favoriteEvents);
            this.setState({ eventSchedules: schedules, activeTab: "favorevents", userFavoritesEvents: favoriteEvents });
          }
        }
      });
    }
  }

  componentDidMount = async () => {
    //To use with Object Manager
    this.ShowSpinner();

    let user = this.props.vkUser;
    await this.LoadFavoriteData(user);
    //GetUserFavorites
    EventService.GetEvents().then(res => res.json()).then(events => {
      this.setState({ geoObjects: this.CreateGeoObjects(events) });
      //console.log("events were loaded");
    }).catch((error) => { console.log("the error during response to enpoint api/events") }).finally(() => {
      this.HidePopout();
    })

    //GetUserSigns // maybe move this code to LoadFavoriteData ?

    //To use with Map function
    //fetch("/api/events").then(res => res.json()).then(events => this.setState({events:events})).catch((error) => {console.log("the error during response to enpoint api/events") });
    // fetch('/api/users')
    //   .then(res => res.json())
    //   .then(users => this.setState({ users }));
  }


  CreateGeoObjects(eventsResponse) {
    let geoObjects = [];

    for (let i = 0; i <= eventsResponse.length - 1; i++) {

      let event = eventsResponse[i];
      //let testFunction = ()=>{ alert("test");};
      // you can put the test function in the content Body
      geoObjects.push(
        {
          "type": "Feature",
          "id": event.id,
          "title": event.title,
          //"price": event.price,
          // "description": event.description,
          "geometry": { "type": "Point", "coordinates": [event.coordinateX, event.coordinateY] },
          "properties":
          {
            "balloonContentHeader": "<font size=3><b>" + event.title + "</b></font>",
            "balloonContentBody": "",
            "balloonContentFooter": "<font size=1>Будни - до 150р. / выходные - до 200 р.</font>",
            "clusterCaption": "<strong><s>прогулки</s></strong> ",
            "hintContent": "<strong>" + event.title + "</strong>"
          }
        }
      )
    }
    return geoObjects;
  }

  go = e => {
    let currentPanel = this.state.mainPanel;
    if (e.currentTarget.dataset.to == "eventsPanel") {
      this.setState({ activeTab: "allevents" });
    }
    this.setState({ previousPanel: currentPanel, mainPanel: e.currentTarget.dataset.to });
  };

  ShowSpinner = () => {
    this.setState({ popout: <ScreenSpinner /> });
  }

  HidePopout = () => {
    this.setState({ popout: null });
  }

  userVKAllowMessagesFromGroup = async () => {
    let isMessagesFromGroupAllowed = false;
    let user = this.props.vkUser;
    if (user != null) {
      let agulyaGroupId = 152403838;
      let groupAccessToken = "731a070a5365b4b69e69b58ed5f190972a5f758814817918bf015128424153e59fbe7202370f841bfd8b5";

      await bridge.send("VKWebAppCallAPIMethod", { "method": "messages.isMessagesFromGroupAllowed", "request_id": "AgulyaRequestTest", "params": { "user_id": user.id, "group_id": agulyaGroupId, "v": "5.122", "access_token": groupAccessToken } }).then(async (res) => {
        let isAllowed = res.response.is_allowed;

        if (isAllowed == false) {
          await bridge.send("VKWebAppAllowMessagesFromGroup", { "group_id": agulyaGroupId, "key": "agulyaMessageKey" + user.id }).then((allowMessagesFromGroupResult) => {

            isMessagesFromGroupAllowed = allowMessagesFromGroupResult.result;
          }).catch(() => {
            // message that we need the vk subscription 
            this.OpenAlert();
          });
        }
        else {

          isMessagesFromGroupAllowed = isAllowed;
        }
      });
    }

    return isMessagesFromGroupAllowed;
  }

  subscribeAction = async () => {

    if (this.props.vkUser == null) {
      //TODO alert: that you have to enter to the app through the VK platform 
      // and that we are working to make another ways of authentification.
      this.OpenSignAlert();
    }
    else {
      //User is specified
      await this.userVKAllowMessagesFromGroup().then((isAllowed) => {

        if (isAllowed == true) {
          //If user is VK user and has permit the messages from groups ,then add subscription
          let checkSubscriptionBody = {
            "user": this.props.vkUser,
            "eventId": this.state.selectedEvent.id
          };

          this.ShowSpinner();
          fetch("/api/subscribes/add", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(checkSubscriptionBody)
          }).then(res => {

            if (res.ok) {
              this.setState({ subscribed: true });
            }

          })
            .catch((error) => {

            }).finally(() => {
              this.HidePopout();
            });
        }
        else {
          this.OpenAlert();
        }
      });
    }
  };

  unSubscribeAction = () => {

    let checkSubscriptionBody = {
      "user": this.props.vkUser,
      "eventId": this.state.selectedEvent.id
    };

    this.ShowSpinner();
    fetch("/api/subscribes/delete", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(checkSubscriptionBody)
    }
    ).then(res => {
      if (res.ok) {
        this.setState({ subscribed: false });
      }
    })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        this.HidePopout();
      });
  };

  disableFavoriteAction = () => {

    this.ShowSpinner();
    UserFavoritesService.DeleteFavoriteEvent(this.props.vkUser, this.state.selectedEvent.id).then(res => {
      if (res.ok) {
        this.setState({ isFavorite: false });
      }
    })
      .catch((error) => {

      }).finally(() => {
        this.UpdateFavoriteData();
        this.HidePopout();
      });
  };

  enableFavoriteAction = () => {

    if (this.props.vkUser == null) {
      //TODO alert: that you have to enter to the app through the VK platform 
      // and that we are working to make another ways of authentification.
      this.OpenSignAlert();
    }
    else {


      this.ShowSpinner();
      UserFavoritesService.AddFavoriteEvent(this.props.vkUser, this.state.selectedEvent.id).then(res => {
        if (res.ok) {
          this.setState({ isFavorite: true });
          //this.LoadFavoriteData();
          this.UpdateFavoriteData();
        }
      })
        .catch((error) => {

        }).finally(() => {
          this.HidePopout();
        });
    }
  };

  OpenFavoriteEvents = () => {
    this.LoadFavoriteData();
    //this.setState({ activeTab: 'favorevents' });

  };

  async OpenScheduleDayInfo(dayInfo) {
    console.log("OpenDayInfo");
    console.log(dayInfo);
    let currentPanel = this.state.mainPanel;
    this.setState({
      selectedDay: dayInfo,
      previousPanel: currentPanel,
      mainPanel: "scheduleDayInfoPanel"
    });

//Check if user is signed at this day. 
if (this.props.vkUser != null) {
  console.log("userFound!");
  console.log(this.state.selectedDay);
  if (dayInfo.times.length != 0) {
    var vkuserid = this.props.vkUser.id;
    var timeInfo = dayInfo.times[0];
    var eventId = timeInfo.eventId;
    var scheduleTimeId = timeInfo.scheduleTimeId;
    var signRequest = {
      eventId: eventId,
      vkUser: this.props.vkUser,
      scheduleTimeId: scheduleTimeId
    }
    console.log("OpenSigns");
    console.log(signRequest);
    await UserSignsService.GetUserScheduleSigns(signRequest).then(res => res.json())
      .then(userSigns => {
        console.log("Result");
        console.log(userSigns)
        // TODO: update to work with multiple times 
        if (userSigns.length > 0){
          if (userSigns[0].scheduleTimeId == timeInfo.scheduleTimeId)
          {
            timeInfo.isSigned = true;
          }

          
        }
        //TODO:  Set this day as Signed ! 
      }).catch(e => {
        console.log("error");
        console.log(e);

      })
      .finally(() => this.HidePopout());
  }
}
//




  };

  OpenResponsibleProfilePanel(selectedUserInfo) {
    let userId = selectedUserInfo.userId;

    // Get user info from the server
    this.ShowSpinner();
    UserService.GetUser(userId).then(res => res.json()).then(userInfo => {

      let userImages = userInfo.images.map(x => {
        return {
          original: x.fileName,
          thumbnail: x.fileName,
          originalClass: "imageGalleryClass"
        }
      })
      let currentPanel = this.state.mainPanel;
      this.setState({
        previousPanel: currentPanel,
        mainPanel: "userInfoPanel",
        selectedUser: userInfo,
        selectedUserImages: userImages
      })

    }).catch((err) => console.log(err)).finally(() => this.HidePopout());

  }

  OpenSchedulePanelByEventObject = (eventInfo) => {
    let eventId = eventInfo.id;
    if (eventId == null || eventId == 0) {
      eventId = eventInfo.eventId;
    }

    this.OpenSchedulePanel(eventId);
  }

  OpenSchedulePanel = (eventId) => {
    this.ShowSpinner();
    EventService.GetEvent(eventId).then(res => res.json()).then(event => {
      //console.log(event);
      //check if is favorite 
      let isFavorite = this.state.userFavoritesEvents.some(item => eventId == item.eventId);
      if (isFavorite) {
        this.setState({ isFavorite: true });
      }
      else {
        this.setState({ isFavorite: false })
      }


      let imagesForGallery = event.images.map(x => {
        return {
          original: x.fileName,
          thumbnail: x.fileName,
          originalClass: "imageGalleryClass"
        }
      });

      let eventSchedules = FillScheduleTableTemplateData(event.schedules);


      this.setState({
        subscribed: false,
        selectedEvent: event,
        eventImages: imagesForGallery,
        mainPanel: "schedulePanel",
        eventSchedules: eventSchedules,
        // selectedEventImages:event.images
      });

      if (this.props.vkUser != null) {
        let checkSubscriptionBody = {
          "user": this.props.vkUser,
          "eventId": eventId
        };

        fetch("/api/subscribes", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(checkSubscriptionBody)
        })
          .then(res => res.json())
          .then(response => {

            if (response.isUserSubscribed == true) {
              this.setState({ subscribed: true });
            }

          }).catch((ex) => console.log(ex));
      }
    }).finally(() => this.HidePopout());
  }

  UnSignToEvent = async () => {
    if (this.props.vkUser == null) {
      this.OpenSignAlert();
    }
    else {
      this.ShowSpinner();
      if (this.state.selectedDay.times.length != 0) {
        var timeInfo = this.state.selectedDay.times[0];
        var eventId = timeInfo.eventId;
        var scheduleTimeId = timeInfo.scheduleTimeId;
        var signRequest = {
          eventId: eventId,
          vkUser: this.props.vkUser,
          scheduleTimeId: scheduleTimeId
        }
        console.log(signRequest);
        await UserSignsService.UnsignFromEventSchedule(signRequest)
          .then(res => {
            console.log(res);
            timeInfo.isSigned = false;
            //TODO:  Set this day as Signed ! 
          }).catch(e => {
            console.log("error");
            console.log(e);

          })
          .finally(() => this.HidePopout());
      }
    }
  }

  SignToEvent = async () => {
    if (this.props.vkUser == null) {
      this.OpenSignAlert();
    }
    else {
      this.ShowSpinner();
      if (this.state.selectedDay.times.length != 0) {
        var vkuserid = this.props.vkUser.id;
        var timeInfo = this.state.selectedDay.times[0];
        var eventId = timeInfo.eventId;
        var scheduleTimeId = timeInfo.scheduleTimeId;
        var signRequest = {
          eventId: eventId,
          vkUser: this.props.vkUser,
          scheduleTimeId: scheduleTimeId
        }
        console.log(signRequest);
        await UserSignsService.SignToEventSchedule(signRequest)
          .then(res => {
            console.log(res);
            timeInfo.isSigned = true;
            //TODO:  Set this day as Signed ! 
          }).catch(e => {
            console.log("error");
            console.log(e);

          })
          .finally(() => this.HidePopout());
      }
    }
  }


  setZeroToNumber(i) { let s = i + ""; let len = i.length || 2; while (s.length < len) s = "0" + s; return s; }

  render() {
    // window.TestFunction = function(x) {
    //   alert("clicked " + x );
    // };

    let curr = new Date(); // get current date
    let firstday = new Date();
    let dayOfWeek = curr.getDay();
    if (dayOfWeek == 0) {
      dayOfWeek = 7;
    }

    firstday.setDate(curr.getDate() - dayOfWeek + 1);
    let lastday = new Date();
    lastday.setDate(curr.getDate() + 7 - dayOfWeek);

    let subscriptionDiv;
    const isSubscribed = this.state.subscribed;
    if (isSubscribed) {
      subscriptionDiv = <Icon28NotificationOutline className="headerIcon pointer" onClick={this.unSubscribeAction} />;
    }
    else {
      subscriptionDiv = <Icon28NotificationsOutline className="headerIcon pointer" onClick={this.subscribeAction} />;
    };

    let favoriteDiv;
    let isFavorite = this.state.isFavorite;
    if (isFavorite) {
      favoriteDiv = <Icon28favorite className="pointer" onClick={this.disableFavoriteAction} />;
    }
    else {
      favoriteDiv = <Icon28FavoriteOutline className="pointer" onClick={this.enableFavoriteAction} />;
    };

    var isLoggedIn = false;
    let mainPageHeaderRightSection;
    if (isLoggedIn) {

    }
    else {
      //mainPageHeaderRightSection = <Icon28DoorArrowLeftOutline> </Icon28DoorArrowLeftOutline>
    }


    let schedulePanelHeaderRightSection = <React.Fragment>{subscriptionDiv} {favoriteDiv} </React.Fragment>



    return (
      <View id={this.props.id} popout={this.state.popout} activePanel={this.state.mainPanel}>
        <Panel id="eventsPanel">
          <PanelHeader right={mainPageHeaderRightSection}>
            Площадки
          </PanelHeader>
          <Tabs>
            <TabsItem
              onClick={() => this.setState({ activeTab: 'allevents' })}
              selected={this.state.activeTab === 'allevents'}
            >
              Все площадки
            </TabsItem>
            <TabsItem
              onClick={() => this.OpenFavoriteEvents()}
              selected={this.state.activeTab === 'favorevents'}
            >
              Избранные площадки {this.state.userFavoritesEvents.length}
            </TabsItem>
          </Tabs>
          {this.state.activeTab === "allevents" &&
            <YMaps>
              <h3 className="text-center">Найдите площадку в вашем районе и кликните на ней.</h3>

              <Map className="events-map"
                state={{
                  center: [59.9661, 30.3113],
                  zoom: 11,
                  controls: ['zoomControl', 'fullscreenControl']
                }}
                modules={['control.ZoomControl', 'control.FullscreenControl']}
              >
                <ObjectManager
                  options={{
                    clusterize: true,
                    gridSize: 10,
                  }}
                  objects={{
                    openBalloonOnClick: true,
                    preset: 'islands#greenDotIcon',
                  }}
                  clusters={{
                    preset: 'islands#redClusterIcons',
                  }}
                  //filter={object => object.id % 2 === 0}
                  defaultFeatures={this.state.geoObjects}
                  //defaultFeatures={this.state.eventsTest}
                  modules={[
                    //'objectManager.addon.objectsBalloon',
                    'objectManager.addon.objectsHint',
                  ]}

                  instanceRef={ref => {
                    if (ref != null) {
                      ref.objects.events.add('click', (e) => {
                        // Используем айдишник для того, чтобы далее получить инфу по метке
                        if (this.state.geoObjects != null) {
                          const objectId = e.get('objectId');
                          this.OpenSchedulePanelByEventObject(ref.objects.getById(objectId));
                          //console.log("element clicked by the map");
                          //console.log(ref.objects.getById(objectId));
                        }

                      })
                    }
                  }
                  }
                />
              </Map>
            </YMaps>
          }
          {this.state.activeTab === "favorevents" &&
            <div>
              {/* {this.state.userFavoritesEvents.map((x) => <SimpleCell className="cell-onhover pointer" onClick={() => this.OpenSchedulePanel(x)}>{x.Address}</SimpleCell>)} */}
              <EventScheduleTemplate firstday={firstday} eventSchedules={this.state.eventSchedules} OpenSchedulePanel={this.OpenSchedulePanel} OpenResponsibleProfile={this.OpenResponsibleProfilePanel} useFullVersion="true">
              </EventScheduleTemplate>
            </div>
          }

          {/* <YMaps>
           <h3 className="text-center">Найдите площадку в вашем районе и кликните на ней.</h3>
            
            <Map className="events-map"
              state={{
                center: [59.9661, 30.3113],
                zoom: 11,
                controls: ['zoomControl', 'fullscreenControl']
              }}
              modules={['control.ZoomControl', 'control.FullscreenControl']}
            >
              <ObjectManager
                options={{
                  clusterize: true,
                  gridSize: 10,
                }}
                objects={{
                  openBalloonOnClick: true,
                  preset: 'islands#greenDotIcon',
                }}
                clusters={{
                  preset: 'islands#redClusterIcons',
                }}
                //filter={object => object.id % 2 === 0}
                defaultFeatures={this.state.geoObjects}
                //defaultFeatures={this.state.eventsTest}
                modules={[
                  //'objectManager.addon.objectsBalloon',
                  'objectManager.addon.objectsHint',
                ]}

                instanceRef={ref => {
                  if (ref != null) {
                    ref.objects.events.add('click', (e) => {
                      // Используем айдишник для того, чтобы далее получить инфу по метке
                      if (this.state.geoObjects != null) {
                        const objectId = e.get('objectId');
                        this.OpenSchedulePanel(ref.objects.getById(objectId));
                        //console.log("element clicked by the map");
                        //console.log(ref.objects.getById(objectId));
                      }

                    })
                  }
                }
                }
              />
            </Map>
          </YMaps> */}
        </Panel>


        <Panel id="schedulePanel">
          <PanelHeader left={<PanelHeaderBack onClick={this.go}
            data-to="eventsPanel" />}
            right={schedulePanelHeaderRightSection}
          >
            {this.state.selectedEvent.address}
          </PanelHeader>
          <div className="baloonContentBody info-section">
            <h3 className="centered text-center"><b>Расписание прогулок {this.setZeroToNumber(firstday.getDate())}.{this.setZeroToNumber(firstday.getMonth() + 1)} - {this.setZeroToNumber(lastday.getDate())}.{this.setZeroToNumber(lastday.getMonth() + 1)} </b></h3>

            <div className="event-section-schedule">
              <EventScheduleTemplateLight firstday={firstday} eventSchedules={this.state.eventSchedules} OpenScheduleDayInfo={this.OpenScheduleDayInfo} OpenResponsibleProfile={this.OpenResponsibleProfilePanel}>
              </EventScheduleTemplateLight>

            </div>

            {/* <button size="x1" onClick="window.TestFunction">Test Button with global function to use in javascript directly lifehack </button> */}




            <div className="block">
              {this.state.selectedEvent.notifications && <div>
                <div className="text-center">Сообщение от ведущего</div>
                {this.state.selectedEvent.notifications.map((x) =>

                  <Banner
                    before={
                      <Avatar size={28} style={{ backgroundImage: warningGradient }}>
                        <span style={{ color: '#fff' }}>!</span>
                      </Avatar>
                    }
                    header={x.createDate}
                    subheader={
                      <React.Fragment>
                        {x.notificationText}

                      </React.Fragment>
                    }
                  />
                )}
              </div>
              }
            </div>
          </div>
          <div className="baloonContentBody info-section">
            {this.state.selectedEvent.images &&
              <div class="imageGallerySection">
                <ImageGallery items={this.state.eventImages}
                  showPlayButton={false}
                  showThumbnails={false} />
              </div>}

            <p>
              {this.state.selectedEvent.description}
            </p>
          </div>
        </Panel>



        <Panel id="userInfoPanel">
          <PanelHeader left={<PanelHeaderBack onClick={this.go} data-to={this.state.previousPanel} />}>
            Информация о ведущем
          </PanelHeader>
          <div className="baloonContentBody info-section">
            <div className="inline-block event-section-schedule">


              <h5>{this.state.selectedUser.surname} {this.state.selectedUser.name} {this.state.selectedUser.middlename}</h5>
              {this.state.selectedUser.email && <h5>E-mail:<a href={"mailto:" + this.state.selectedUser.email}>{this.state.selectedUser.email}</a></h5>}
              {this.state.selectedUser.city && <h5>Город:{this.state.selectedUser.city}</h5>}
              {this.state.selectedUser.address && <h5>Адрес:{this.state.selectedUser.address}</h5>}
              <h5>О себе: </h5>
              <p>{this.state.selectedUser.description}</p>


            </div>

            <div className="inline-block event-section-info">

              {this.state.selectedUser.images &&
                <div class="imageGallerySection">
                  <ImageGallery items={this.state.selectedUserImages}
                    showPlayButton={false}
                    showThumbnails={false} />

                </div>}
              {!this.state.selectedUser.images && <div>Автор не загрузил ещё своих фотографий!</div>}
            </div>
          </div>
          {/* <button size="x1" onClick="window.TestFunction">Test Button with global function to use in javascript directly lifehack </button> */}
        </Panel>

        <Panel id="scheduleDayInfoPanel">
          <PanelHeader left={<PanelHeaderBack onClick={this.go} data-to={this.state.previousPanel} />}>
            {this.state.selectedEvent.address}
          </PanelHeader>
          {/* <div className="baloonContentBody info-section"> */}
          <div className="padding-left-10 text-center">
            <h2 className=''> {this.state.selectedDay.dayTitleFull}</h2>
            <div className='event-section-schedule'>
              {this.state.selectedDay != null &&
                this.state.selectedDay.times.map((x) =>
                  <div className='schedule-panel-row'>
                    <div className="schedule-panel-left">{x.time}</div>
                    <div className="schedule-panel-div"> {x.responsible.name} {x.responsible.surname} </div>
                    <div className="schedule-panel-right">
                      {!x.isSigned && <Button size="l" className="pointer" onClick={this.SignToEvent}> Записаться на прогулку</Button>}
                      {x.isSigned &&
                        <div>
                          <Button size="l" className="pointer" > Вы записаны на прогулку</Button>
                          <span className="pointer text-red" onClick={this.UnSignToEvent}> отписаться</span>
                        </div>

                      }

                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="centered block-sm">
            <div className='inline-block width-auto text-top'>
              <b>Также запись возможна:</b>
            </div>
            <div className="inline-block">
              {this.state.selectedEvent.responsibleUser?.tel &&
                <div className="list-item">- по телефону <a href={"tel:" + this.state.selectedEvent.responsibleUser.tel}>{this.state.selectedEvent.responsibleUser.tel}</a></div>
              }
              <div className='list-item'>- в <a href="https://vk.com/agulya_club"> Группе Вконтакте </a></div>
              {/* <ul>
                  {this.state.selectedEvent.responsibleUser?.tel &&
                    <li>по телефону <a href={"tel:" + this.state.selectedEvent.responsibleUser.tel}>{this.state.selectedEvent.responsibleUser.tel}</a></li>
                  }
                  <li>в <a href="https://vk.com/agulya_club"> Группе Вконтакте </a></li>
                </ul> */}
            </div>

            <div className="centered">
              <p>Стоимость: {this.state.selectedDay.price}</p>
              <b></b>
            </div>


          </div>
          <h2 class="text-center">Первая прогулка - <span className="text-success">Бесплатно!</span></h2>
          {/* </div> */}
        </Panel>
      </View>
    )
  }
}

export default EventsView;